export const checkupConstants = {

    GET_CHECK_UP_STATUS_COUNT : 'GET_CHECK_UP_STATUS_COUNT',
    GET_CHECK_UP_STATUS_COUNT_PENDING : 'GET_CHECK_UP_STATUS_COUNT_PENDING',
    GET_CHECK_UP_STATUS_COUNT_FULFILLED : 'GET_CHECK_UP_STATUS_COUNT_FULFILLED',
    GET_CHECK_UP_STATUS_COUNT_FAILURE : 'GET_CHECK_UP_STATUS_COUNT_FAILURE',
    GET_CHECK_UP_STATUS_COUNT_REJECTED : 'GET_CHECK_UP_STATUS_COUNT_REJECTED',


    GET_CHECK_UP_PENDING : 'GET_CHECK_UP_PENDING',
    GET_CHECK_UP_PENDING_PENDING : 'GET_CHECK_UP_PENDING_PENDING',
    GET_CHECK_UP_PENDING_FULFILLED : 'GET_CHECK_UP_PENDING_FULFILLED',
    GET_CHECK_UP_PENDING_FAILURE : 'GET_CHECK_UP_PENDING_FAILURE',
    GET_CHECK_UP_PENDING_REJECTED : 'GET_CHECK_UP_PENDING_REJECTED',


    GET_CHECK_UP_ROOM_ALLOCATED : 'GET_CHECK_UP_ROOM_ALLOCATED',
    GET_CHECK_UP_ROOM_ALLOCATED_PENDING : 'GET_CHECK_UP_ROOM_ALLOCATED_PENDING',
    GET_CHECK_UP_ROOM_ALLOCATED_FULFILLED : 'GET_CHECK_UP_ROOM_ALLOCATED_FULFILLED',
    GET_CHECK_UP_ROOM_ALLOCATED_FAILURE : 'GET_CHECK_UP_ROOM_ALLOCATED_FAILURE',
    GET_CHECK_UP_ROOM_ALLOCATED_REJECTED : 'GET_CHECK_UP_ROOM_ALLOCATED_REJECTED',

    GET_CHECK_UP_FINISHED : 'GET_CHECK_UP_FINISHED',
    GET_CHECK_UP_FINISHED_PENDING : 'GET_CHECK_UP_FINISHED_PENDING',
    GET_CHECK_UP_FINISHED_FULFILLED : 'GET_CHECK_UP_FINISHED_FULFILLED',
    GET_CHECK_UP_FINISHED_FAILURE : 'GET_CHECK_UP_FINISHED_FAILURE',
    GET_CHECK_UP_FINISHED_REJECTED : 'GET_CHECK_UP_FINISHED_REJECTED',

    GET_CHECK_UP_ROOMS : 'GET_CHECK_UP_ROOMS',
    GET_CHECK_UP_ROOMS_PENDING : 'GET_CHECK_UP_ROOMS_PENDING',
    GET_CHECK_UP_ROOMS_FULFILLED : 'GET_CHECK_UP_ROOMS_FULFILLED',
    GET_CHECK_UP_ROOMS_FAILURE : 'GET_CHECK_UP_ROOMS_FAILURE',
    GET_CHECK_UP_ROOMS_REJECTED : 'GET_CHECK_UP_ROOMS_REJECTED',

    GET_MEDICINES : 'GET_MEDICINES',
    GET_MEDICINES_PENDING : 'GET_MEDICINES_PENDING',
    GET_MEDICINES_FULFILLED : 'GET_MEDICINES_FULFILLED',
    GET_MEDICINES_FAILURE : 'GET_MEDICINES_FAILURE',
    GET_MEDICINES_REJECTED : 'GET_MEDICINES_REJECTED',

    GET_SERVICES : 'GET_SERVICES',
    GET_SERVICES_PENDING : 'GET_SERVICES_PENDING',
    GET_SERVICES_FULFILLED : 'GET_SERVICES_FULFILLED',
    GET_SERVICES_FAILURE : 'GET_SERVICES_FAILURE',
    GET_SERVICES_REJECTED : 'GET_SERVICES_REJECTED',

    GET_CHECK_UP_PRESCRIPTION : 'GET_CHECK_UP_PRESCRIPTION',
    GET_CHECK_UP_PRESCRIPTION_PENDING : 'GET_CHECK_UP_PRESCRIPTION_PENDING',
    GET_CHECK_UP_PRESCRIPTION_FULFILLED : 'GET_CHECK_UP_PRESCRIPTION_FULFILLED',
    GET_CHECK_UP_PRESCRIPTION_FAILURE : 'GET_CHECK_UP_PRESCRIPTION_FAILURE',
    GET_CHECK_UP_PRESCRIPTION_REJECTED : 'GET_CHECK_UP_PRESCRIPTION_REJECTED',

    DELETE_CHECK_UP_PRESCRIPTION_MEDICINE : 'DELETE_CHECK_UP_PRESCRIPTION_MEDICINE',
    DELETE_CHECK_UP_PRESCRIPTION_MEDICINE_PENDING : 'DELETE_CHECK_UP_PRESCRIPTION_MEDICINE_PENDING',
    DELETE_CHECK_UP_PRESCRIPTION_MEDICINE_FULFILLED : 'DELETE_CHECK_UP_PRESCRIPTION_MEDICINE_FULFILLED',
    DELETE_CHECK_UP_PRESCRIPTION_MEDICINE_FAILURE : 'DELETE_CHECK_UP_PRESCRIPTION_MEDICINE_FAILURE',
    DELETE_CHECK_UP_PRESCRIPTION_MEDICINE_REJECTED : 'DELETE_CHECK_UP_PRESCRIPTION_MEDICINE_REJECTED',

    DELETE_CHECK_UP_PRESCRIPTION_SERVICE : 'DELETE_CHECK_UP_PRESCRIPTION_SERVICE',
    DELETE_CHECK_UP_PRESCRIPTION_SERVICE_PENDING : 'DELETE_CHECK_UP_PRESCRIPTION_SERVICE_PENDING',
    DELETE_CHECK_UP_PRESCRIPTION_SERVICE_FULFILLED : 'DELETE_CHECK_UP_PRESCRIPTION_SERVICE_FULFILLED',
    DELETE_CHECK_UP_PRESCRIPTION_SERVICE_FAILURE : 'DELETE_CHECK_UP_PRESCRIPTION_SERVICE_FAILURE',
    DELETE_CHECK_UP_PRESCRIPTION_SERVICE_REJECTED : 'DELETE_CHECK_UP_PRESCRIPTION_SERVICE_REJECTED',


    GET_DOSAGE_TEMPLATE : 'GET_DOSAGE_TEMPLATE',
    GET_DOSAGE_TEMPLATE_PENDING : 'GET_DOSAGE_TEMPLATE_PENDING',
    GET_DOSAGE_TEMPLATE_FULFILLED : 'GET_DOSAGE_TEMPLATE_FULFILLED',
    GET_DOSAGE_TEMPLATE_FAILURE : 'GET_DOSAGE_TEMPLATE_FAILURE',
    GET_DOSAGE_TEMPLATE_REJECTED : 'GET_DOSAGE_TEMPLATE_REJECTED',


    SAVE_CHECK_UP_MEDICINE_PRESCRIPTIONS : 'SAVE_CHECK_UP_MEDICINE_PRESCRIPTIONS',
    SAVE_CHECK_UP_MEDICINE_PRESCRIPTIONS_PENDING : 'SAVE_CHECK_UP_MEDICINE_PRESCRIPTIONS_PENDING',
    SAVE_CHECK_UP_MEDICINE_PRESCRIPTIONS_FULFILLED : 'SAVE_CHECK_UP_MEDICINE_PRESCRIPTIONS_FULFILLED',
    SAVE_CHECK_UP_MEDICINE_PRESCRIPTIONS_FAILURE : 'SAVE_CHECK_UP_MEDICINE_PRESCRIPTIONS_FAILURE',
    SAVE_CHECK_UP_MEDICINE_PRESCRIPTIONS_REJECTED : 'SAVE_CHECK_UP_MEDICINE_PRESCRIPTIONS_REJECTED',

    SAVE_CHECK_UP_IMAGES_PRESCRIPTIONS : 'SAVE_CHECK_UP_IMAGES_PRESCRIPTIONS',
    SAVE_CHECK_UP_IMAGES_PRESCRIPTIONS_PENDING : 'SAVE_CHECK_UP_IMAGES_PRESCRIPTIONS_PENDING',
    SAVE_CHECK_UP_IMAGES_PRESCRIPTIONS_FULFILLED : 'SAVE_CHECK_UP_IMAGES_PRESCRIPTIONS_FULFILLED',
    SAVE_CHECK_UP_IMAGES_PRESCRIPTIONS_FAILURE : 'SAVE_CHECK_UP_IMAGES_PRESCRIPTIONS_FAILURE',
    SAVE_CHECK_UP_IMAGES_PRESCRIPTIONS_REJECTED : 'SAVE_CHECK_UP_IMAGES_PRESCRIPTIONS_REJECTED',

    SAVE_CHECK_UP_SERVICE_PRESCRIPTIONS : 'SAVE_CHECK_UP_SERVICE_PRESCRIPTIONS',
    SAVE_CHECK_UP_SERVICE_PRESCRIPTIONS_PENDING : 'SAVE_CHECK_UP_SERVICE_PRESCRIPTIONS_PENDING',
    SAVE_CHECK_UP_SERVICE_PRESCRIPTIONS_FULFILLED : 'SAVE_CHECK_UP_SERVICE_PRESCRIPTIONS_FULFILLED',
    SAVE_CHECK_UP_SERVICE_PRESCRIPTIONS_FAILURE : 'SAVE_CHECK_UP_SERVICE_PRESCRIPTIONS_FAILURE',
    SAVE_CHECK_UP_SERVICE_PRESCRIPTIONS_REJECTED : 'SAVE_CHECK_UP_SERVICE_PRESCRIPTIONS_REJECTED',


    GET_CHECK_UP_EMPLOYEES : 'GET_CHECK_UP_EMPLOYEES',
    GET_CHECK_UP_EMPLOYEES_PENDING : 'GET_CHECK_UP_EMPLOYEES_PENDING',
    GET_CHECK_UP_EMPLOYEES_FULFILLED : 'GET_CHECK_UP_EMPLOYEES_FULFILLED',
    GET_CHECK_UP_EMPLOYEES_FAILURE : 'GET_CHECK_UP_EMPLOYEES_FAILURE',
    GET_CHECK_UP_EMPLOYEES_REJECTED : 'GET_CHECK_UP_EMPLOYEES_REJECTED',


    SAVE_CHECK_UP_ROOM_ALLOCATIONS : 'SAVE_CHECK_UP_ROOM_ALLOCATIONS',
    SAVE_CHECK_UP_ROOM_ALLOCATIONS_PENDING : 'SAVE_CHECK_UP_ROOM_ALLOCATIONS_PENDING',
    SAVE_CHECK_UP_ROOM_ALLOCATIONS_FULFILLED : 'SAVE_CHECK_UP_ROOM_ALLOCATIONS_FULFILLED',
    SAVE_CHECK_UP_ROOM_ALLOCATIONS_FAILURE : 'SAVE_CHECK_UP_ROOM_ALLOCATIONS_FAILURE',
    SAVE_CHECK_UP_ROOM_ALLOCATIONS_REJECTED : 'SAVE_CHECK_UP_ROOM_ALLOCATIONS_REJECTED',

    GET_CHECK_UP_ROOM_BASIC_INFO : 'GET_CHECK_UP_ROOM_BASIC_INFO',
    GET_CHECK_UP_ROOM_BASIC_INFO_PENDING : 'GET_CHECK_UP_ROOM_BASIC_INFO_PENDING',
    GET_CHECK_UP_ROOM_BASIC_INFO_FULFILLED : 'GET_CHECK_UP_ROOM_BASIC_INFO_FULFILLED',
    GET_CHECK_UP_ROOM_BASIC_INFO_FAILURE : 'GET_CHECK_UP_ROOM_BASIC_INFO_FAILURE',
    GET_CHECK_UP_ROOM_BASIC_INFO_REJECTED : 'GET_CHECK_UP_ROOM_BASIC_INFO_REJECTED',

    GET_CHECK_UP_VITAL_SIGNS_FOR_PATIENT : 'GET_CHECK_UP_VITAL_SIGNS_FOR_PATIENT',
    GET_CHECK_UP_VITAL_SIGNS_FOR_PATIENT_PENDING : 'GET_CHECK_UP_VITAL_SIGNS_FOR_PATIENT_PENDING',
    GET_CHECK_UP_VITAL_SIGNS_FOR_PATIENT_FULFILLED : 'GET_CHECK_UP_VITAL_SIGNS_FOR_PATIENT_FULFILLED',
    GET_CHECK_UP_VITAL_SIGNS_FOR_PATIENT_FAILURE : 'GET_CHECK_UP_VITAL_SIGNS_FOR_PATIENT_FAILURE',
    GET_CHECK_UP_VITAL_SIGNS_FOR_PATIENT_REJECTED : 'GET_CHECK_UP_VITAL_SIGNS_FOR_PATIENT_REJECTED',

    GET_CHECK_UP_VITAL_SIGNS_ALL : 'GET_CHECK_UP_VITAL_SIGNS_ALL',
    GET_CHECK_UP_VITAL_SIGNS_ALL_PENDING : 'GET_CHECK_UP_VITAL_SIGNS_ALL_PENDING',
    GET_CHECK_UP_VITAL_SIGNS_ALL_FULFILLED : 'GET_CHECK_UP_VITAL_SIGNS_ALL_FULFILLED',
    GET_CHECK_UP_VITAL_SIGNS_ALL_FAILURE : 'GET_CHECK_UP_VITAL_SIGNS_ALL_FAILURE',
    GET_CHECK_UP_VITAL_SIGNS_ALL_REJECTED : 'GET_CHECK_UP_VITAL_SIGNS_ALL_REJECTED',



    GET_CHECK_UP_ALL_APPOINTMENTS : 'GET_CHECK_UP_ALL_APPOINTMENTS',
    GET_CHECK_UP_ALL_APPOINTMENTS_PENDING : 'GET_CHECK_UP_ALL_APPOINTMENTS_PENDING',
    GET_CHECK_UP_ALL_APPOINTMENTS_FULFILLED : 'GET_CHECK_UP_ALL_APPOINTMENTS_FULFILLED',
    GET_CHECK_UP_ALL_APPOINTMENTS_FAILURE : 'GET_CHECK_UP_ALL_APPOINTMENTS_FAILURE',
    GET_CHECK_UP_ALL_APPOINTMENTS_REJECTED : 'GET_CHECK_UP_ALL_APPOINTMENTS_REJECTED',


    GET_CHECK_UP_ONE_APPOINTMENTS : 'GET_CHECK_UP_ONE_APPOINTMENTS',
    GET_CHECK_UP_ONE_APPOINTMENTS_PENDING : 'GET_CHECK_UP_ONE_APPOINTMENTS_PENDING',
    GET_CHECK_UP_ONE_APPOINTMENTS_FULFILLED : 'GET_CHECK_UP_ONE_APPOINTMENTS_FULFILLED',
    GET_CHECK_UP_ONE_APPOINTMENTS_FAILURE : 'GET_CHECK_UP_ONE_APPOINTMENTS_FAILURE',
    GET_CHECK_UP_ONE_APPOINTMENTS_REJECTED : 'GET_CHECK_UP_ONE_APPOINTMENTS_REJECTED',


    START_CHECK_UP_ONE_APPOINTMENTS : 'START_CHECK_UP_ONE_APPOINTMENTS',
    START_CHECK_UP_ONE_APPOINTMENTS_PENDING : 'START_CHECK_UP_ONE_APPOINTMENTS_PENDING',
    START_CHECK_UP_ONE_APPOINTMENTS_FULFILLED : 'START_CHECK_UP_ONE_APPOINTMENTS_FULFILLED',
    START_CHECK_UP_ONE_APPOINTMENTS_FAILURE : 'START_CHECK_UP_ONE_APPOINTMENTS_FAILURE',
    START_CHECK_UP_ONE_APPOINTMENTS_REJECTED : 'START_CHECK_UP_ONE_APPOINTMENTS_REJECTED',


    END_CHECK_UP_ONE_APPOINTMENTS : 'END_CHECK_UP_ONE_APPOINTMENTS',
    END_CHECK_UP_ONE_APPOINTMENTS_PENDING : 'END_CHECK_UP_ONE_APPOINTMENTS_PENDING',
    END_CHECK_UP_ONE_APPOINTMENTS_FULFILLED : 'END_CHECK_UP_ONE_APPOINTMENTS_FULFILLED',
    END_CHECK_UP_ONE_APPOINTMENTS_FAILURE : 'END_CHECK_UP_ONE_APPOINTMENTS_FAILURE',
    END_CHECK_UP_ONE_APPOINTMENTS_REJECTED : 'END_CHECK_UP_ONE_APPOINTMENTS_REJECTED',


    VITAL_SIGNS_SAVE_PATIENT : 'VITAL_SIGNS_SAVE_PATIENT',
    VITAL_SIGNS_SAVE_PATIENT_PENDING : 'VITAL_SIGNS_SAVE_PATIENT_PENDING',
    VITAL_SIGNS_SAVE_PATIENT_FULFILLED : 'VITAL_SIGNS_SAVE_PATIENT_FULFILLED',
    VITAL_SIGNS_SAVE_PATIENT_FAILURE : 'VITAL_SIGNS_SAVE_PATIENT_FAILURE',
    VITAL_SIGNS_SAVE_PATIENT_REJECTED : 'VITAL_SIGNS_SAVE_PATIENT_REJECTED',
    
    CHECKUP_SAVED_STATUS_CHANGE : 'CHECKUP_SAVED_STATUS_CHANGE',


};
