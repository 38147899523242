import { applyMiddleware, createStore } from 'redux';
import { combineReducers } from 'redux';

import logger from 'redux-logger';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import userReducer from './pages/login/redux/reducer.js';
import patientReducer from './pages/patient_or_guardian/redux/reducer.js';
import appointmentReducer from './pages/appointment/redux/reducer.js';
import homeReducer from './pages/home/redux/reducer.js';
import laravelLoginReducer from './pages/login_loader/redux/reducer.js';
import doctorScheduleReducer from './pages/doctor/redux/reducer.js';
import checkupReducer from './pages/checkups/redux/reducer.js';


const middleware = applyMiddleware(promiseMiddleware, thunk, logger);

export default createStore(combineReducers({
    userReducer,
    patientReducer,//show Patients Reducer
    appointmentReducer,
    homeReducer,
    laravelLoginReducer,
    doctorScheduleReducer,
    checkupReducer,
}), middleware)