
import { typeLoadingEnum } from '../../../components/Enums/typeLoading.js';
import {appointmentConstants} from './constants.js';
export default function appointmentReducer(state={
    error: null,
    loading: false,
    fetched: false,
    pageLoading: false,
    typeLoading:typeLoadingEnum.PATIENT,
    // Add Patients
    patients: [],
    patient: {},
    // appointment: {},
    partners: [],
    partner: {},
    guardians: [],
    referredDoctors: [],
    allAppointments:{},
    patientAppointment: {},
    patientType: [],
    doctors: [],
    
    doctorTimeSlots: [],
    doctorTimeSlotAppointments: [],
    canceledAppointment:{},
    isDeletedAppointment:false,
    cancelTimeSlot:false
    

  }, action) {

    state = {...state,saved:false , savedAppointment:false , saveReschedule:false ,isDeletedAppointment:false, cancelTimeSlot:false,leaveTimeSlot:false, updateTimeSlot :false ,error:{}  }

    switch (action.type) {

         //GET Doctor Time Slots for doctor id 
        case appointmentConstants.GET_ALL_APPOINTMENTS_PENDING: {
            return {...state,loading:{...state.loading, getAllAppointments:true},fetched: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  appointmentConstants.GET_ALL_APPOINTMENTS_FULFILLED: {
            return {...state,loading:{...state.loading, getAllAppointments:false},fetched: true, allAppointments:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_ALL_APPOINTMENTS_FAILURE:  {
            return {...state, loading:{...state.loading, getAllAppointments:false},fetched: false,isDeletedAppointment:false,error:action.error}
        }
        case appointmentConstants.GET_ALL_APPOINTMENTS_REJECTED:  {
            return {...state, loading:{...state.loading, getAllAppointments:false},fetched: false,isDeletedAppointment:false,error:action.error}
        }


        case appointmentConstants.GET_ONE_APPOINTMENT_DETAILS_PENDING: {

            return {...state,loading:true,fetched: false,updateTimeSlot :false,isDeletedAppointment:false, user: action.payload}
        }  
        case  appointmentConstants.GET_ONE_APPOINTMENT_DETAILS_FULFILLED: {
            return {...state,loading: false,fetched: false,updateTimeSlot :false, appointment:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_ONE_APPOINTMENT_DETAILS_FAILURE:  {
            return {...state, loading: false,fetched: false,updateTimeSlot :false,isDeletedAppointment:false,error:action.error}
        }
        case appointmentConstants.GET_ONE_APPOINTMENT_DETAILS_REJECTED:  {
            return {...state, loading: false,fetched: false,updateTimeSlot :false,isDeletedAppointment:false,error:action.error}
        }


    

        // Add Appointment
        
            // GET Appointment Patients
            case appointmentConstants.GET_ADD_APPOINTMENT_PATIENTS_PENDING: {
                return {...state,loading:{...state.loading, patientSearch:true}, typeLoading:typeLoadingEnum.PATIENT, fetched: false,isDeletedAppointment:false, user: action.payload}
            }  
            case  appointmentConstants.GET_ADD_APPOINTMENT_PATIENTS_FULFILLED: {
                return {...state,loading: {...state.loading, patientSearch:false},typeLoading:typeLoadingEnum.PATIENT,fetched: true, patients:action?.payload?.data?.data}
            }    
            case appointmentConstants.GET_ADD_APPOINTMENT_PATIENTS_FAILURE:  {
                return {...state, loading: {...state.loading, patientSearch:false},typeLoading:typeLoadingEnum.PATIENT,fetched: false,isDeletedAppointment:false,error:action.error}
            }
            // GET Appointment Guardian
            case appointmentConstants.GET_ADD_APPOINTMENT_GUARDIANS_PENDING: {
                return {...state,loading:{...state.loading, guardianSearch:true},typeLoading:typeLoadingEnum.GUARDIAN,fetched: false,isDeletedAppointment:false, user: action.payload}
            }  
            case  appointmentConstants.GET_ADD_APPOINTMENT_GUARDIANS_FULFILLED: {
                return {...state,loading:{...state.loading, guardianSearch:false},typeLoading:typeLoadingEnum.GUARDIAN,fetched: true, guardians:action?.payload?.data?.data}
            }    
            case appointmentConstants.GET_ADD_APPOINTMENT_GUARDIANS_FAILURE:  {
                return {...state, loading:{...state.loading, guardianSearch:false},typeLoading:typeLoadingEnum.GUARDIAN,fetched: false,isDeletedAppointment:false,error:action.error}
            }
            // GET Appointment Patients
            case appointmentConstants.GET_REFERRED_DOCTORS_PENDING: {
                return {...state,loading:true,typeLoading:typeLoadingEnum.REFERRED_DOCTOR,fetched: false,isDeletedAppointment:false, user: action.payload}
            }  
            case  appointmentConstants.GET_REFERRED_DOCTORS_FULFILLED: {
                return {...state,loading: false,typeLoading:typeLoadingEnum.REFERRED_DOCTOR,fetched: true, referredDoctors:action?.payload?.data?.data}
            }    
            case appointmentConstants.GET_REFERRED_DOCTORS_FAILURE:  {
                return {...state, loading: false,typeLoading:typeLoadingEnum.REFERRED_DOCTOR,fetched: false,isDeletedAppointment:false,error:action.error}
            
            }
            // GET Appointment Patients
            case appointmentConstants.GET_REFERRED_CENTERS_PENDING: {
                return {...state,loading:true,typeLoading:typeLoadingEnum.REFERRED_CENTER,fetched: false,isDeletedAppointment:false, user: action.payload}
            }  
            case  appointmentConstants.GET_REFERRED_CENTERS_FULFILLED: {
                return {...state,loading: false,typeLoading:typeLoadingEnum.REFERRED_CENTER,fetched: true, referredCenter:action?.payload?.data?.data}
            }    
            case appointmentConstants.GET_REFERRED_CENTERS_FAILURE:  {
                return {...state, loading: false,typeLoading:typeLoadingEnum.REFERRED_CENTER,fetched: false,isDeletedAppointment:false,error:action.error}
            
            }
            // GET Appointment Doctor
            case appointmentConstants.GET_DOCTORS_PENDING: {
                return {...state,loading:true,fetched: false,isDeletedAppointment:false, user: action.payload}
            }  
            case  appointmentConstants.GET_DOCTORS_FULFILLED: {
                return {...state,loading: false,fetched: true, doctors:action?.payload?.data?.data}
            }    
            case appointmentConstants.GET_DOCTOR_FAILURE:  {
                return {...state, loading: false,fetched: false,isDeletedAppointment:false,error:action.error}
            }



        case appointmentConstants.GET_MOBILE_NO_PENDING: {
            return {...state,loading:true,fetched: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  appointmentConstants.GET_MOBILE_NO_FULFILLED: {
            return {...state,loading: false,fetched: false, patient:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_MOBILE_NO_FAILURE:  {
            return {...state, loading: false,fetched: false,isDeletedAppointment:false,error:action.error}
        }

        //GET Guardian for patient id 
        case appointmentConstants.GET_GUARDIANS_FOR_PARTNER_PENDING: {
            return {...state,loading:true,fetched: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  appointmentConstants.GET_GUARDIANS_FOR_PARTNER_FULFILLED: {
            return {...state,loading: false,fetched: false, guardians:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_GUARDIANS_FOR_PARTNER_FAILURE:  {
            return {...state, loading: false,error:action.error}
        }
        case appointmentConstants.GET_GUARDIANS_FOR_PARTNER_REJECTED:  {
            return {...state, loading: false,fetched: false,isDeletedAppointment:false,error:action.payload}
        }


        //GET Doctor Time Slots for doctor id 
        case appointmentConstants.GET_DOCTOR_TIME_SLOTS_PENDING: {
            return {...state,loading:true,fetched: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  appointmentConstants.GET_DOCTOR_TIME_SLOTS_FULFILLED: {
            return {...state,loading: false,fetched: false, doctorTimeSlots:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_DOCTOR_TIME_SLOTS_FAILURE:  {
            return {...state, loading: false,fetched: false,isDeletedAppointment:false,error:action.error}
        }
        case appointmentConstants.GET_DOCTOR_TIME_SLOTS_REJECTED:  {
            return {...state, loading: false,fetched: false,isDeletedAppointment:false,error:action.payload}
        }


        //GET Doctor Time Slots Appointment for doctor id 
        case appointmentConstants.GET_DOCTOR_TIME_SLOT_APPOINTMENTS_PENDING: {
            return {...state,loading:true,fetched: false,pageLoading : true,isDeletedAppointment:false, user: action.payload}
        }  
        case  appointmentConstants.GET_DOCTOR_TIME_SLOT_APPOINTMENTS_FULFILLED: {
            return {...state,loading: false,fetched: false,pageLoading:false, doctorTimeSlotAppointments:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_DOCTOR_TIME_SLOT_APPOINTMENTS_FAILURE:  {
            return {...state, loading: false,fetched: false,pageLoading:false,isDeletedAppointment:false,error:action.error}
        }
        case appointmentConstants.GET_DOCTOR_TIME_SLOT_APPOINTMENTS_REJECTED:  {
            return {...state, loading: false,fetched: false,isDeletedAppointment:false,error:action.payload}
        }

        //GET Doctor Time Slots Appointment For patient reschedule for doctor id 
        case appointmentConstants.GET_DOCTOR_TIME_SLOT_RESCHEDULE_APPOINTMENTS_PENDING: {
            return {...state,loading:true,fetched: false,doctorTimeSlotAppointmentsReschedule : [] ,isDeletedAppointment:false, user: action.payload}
        }  
        case  appointmentConstants.GET_DOCTOR_TIME_SLOT_RESCHEDULE_APPOINTMENTS_FULFILLED: {
            return {...state,loading: false,fetched: false, doctorTimeSlotAppointmentsReschedule:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_DOCTOR_TIME_SLOT_RESCHEDULE_APPOINTMENTS_FAILURE:  {
            return {...state, loading: false,fetched: false,isDeletedAppointment:false,error:action.error}
        }
        case appointmentConstants.GET_DOCTOR_TIME_SLOT_RESCHEDULE_APPOINTMENTS_REJECTED:  {
            return {...state, loading: false,fetched: false,isDeletedAppointment:false,error:action.payload}
        }

        // Patient cancel Appointment
        case appointmentConstants.CANCEL_PATIENT_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading , cancelAppointment:true},cancelTimeSlot: false,isDeletedAppointment:false,  user: action.payload}
        }  
        case  appointmentConstants.CANCEL_PATIENT_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading , cancelAppointment:false},cancelTimeSlot: true,canceledAppointment:action?.payload?.data?.data}
        }    
        case appointmentConstants.CANCEL_PATIENT_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading , cancelAppointment:false},cancelTimeSlot: false,isDeletedAppointment:false,error:action.error}
        }
        case appointmentConstants.CANCEL_PATIENT_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading , cancelAppointment:false},cancelTimeSlot: false,isDeletedAppointment:false,error:action.payload}
        }

        //  Doctor cancel Appointment
        case appointmentConstants.CANCEL_DOCTOR_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading , cancelAppointment:true},cancelTimeSlot: false,isDeletedAppointment:false,  user: action.payload}
        }  
        case  appointmentConstants.CANCEL_DOCTOR_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading , cancelAppointment:false},cancelTimeSlot: true,isDeletedAppointment:true}
        }    
        case appointmentConstants.CANCEL_DOCTOR_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading , cancelAppointment:false},cancelTimeSlot: false,isDeletedAppointment:false,error:action.error}
        }
        case appointmentConstants.CANCEL_DOCTOR_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading , cancelAppointment:false},cancelTimeSlot: false,isDeletedAppointment:false,error:action.payload}
        }
        //  Doctor cancel Appointment
        case appointmentConstants.LEAVE_DOCTOR_TIME_SLOT_PENDING: {
            return {...state,loading:{...state.loading , cancelAppointment:true},leaveTimeSlot: false,isDeletedAppointment:false,  user: action.payload}
        }  
        case  appointmentConstants.LEAVE_DOCTOR_TIME_SLOT_FULFILLED: {
            return {...state,loading:{...state.loading , cancelAppointment:false},leaveTimeSlot: true,isDeletedAppointment:true}
        }    
        case appointmentConstants.LEAVE_DOCTOR_TIME_SLOT_FAILURE:  {
            return {...state, loading:{...state.loading , cancelAppointment:false},leaveTimeSlot: false,isDeletedAppointment:false,error:action.payload.error}
        }
        case appointmentConstants.LEAVE_DOCTOR_TIME_SLOT_REJECTED:  {
            return {...state, loading:{...state.loading , cancelAppointment:false},leaveTimeSlot: false,isDeletedAppointment:false,error:action.payload.error}
        }
        //  Doctor cancel Appointment
        case appointmentConstants.DOCTOR_TIME_SLOT_COUNT_PENDING: {
            return {...state,loading:{...state.loading , cancelAppointment:true},updateTimeSlot: false,isDeletedAppointment:false,  user: action.payload}
        }  
        case  appointmentConstants.DOCTOR_TIME_SLOT_COUNT_FULFILLED: {
            return {...state,loading:{...state.loading , cancelAppointment:false},updateTimeSlot: true,isDeletedAppointment:true}
        }    
        case appointmentConstants.DOCTOR_TIME_SLOT_COUNT_FAILURE:  {
            return {...state, loading:{...state.loading , cancelAppointment:false},updateTimeSlot: false,isDeletedAppointment:false,error:action.payload.error}
        }
        case appointmentConstants.DOCTOR_TIME_SLOT_COUNT_REJECTED:  {
            return {...state, loading:{...state.loading , cancelAppointment:false},updateTimeSlot: false,isDeletedAppointment:false,error:action.payload.error}
        }
        // 
        case appointmentConstants.GET_CHECKUP_FOR_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading , getCheckupAppointment:true},leaveTimeSlot: false,updateTimeSlot :false,isDeletedAppointment:false,  user: action.payload}
        }  
        case  appointmentConstants.GET_CHECKUP_FOR_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading , getCheckupAppointment:false},leaveTimeSlot: true,updateTimeSlot :false,isDeletedAppointment:true}
        }    
        case appointmentConstants.GET_CHECKUP_FOR_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading , getCheckupAppointment:false},leaveTimeSlot: false,updateTimeSlot :false,isDeletedAppointment:false,error:action.error}
        }
        case appointmentConstants.GET_CHECKUP_FOR_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading , getCheckupAppointment:false},leaveTimeSlot: false,updateTimeSlot :false,isDeletedAppointment:false,error:action.payload}
        }
        case appointmentConstants.SAVE_DOCTOR_APPOINTMENT_TRANSFER_PENDING: {
            return {...state,loading:{...state.loading , saveDoctorTransfer:true},savedTransfer: false,updateTimeSlot :false,isDeletedAppointment:false,  user: action.payload}
        }  
        case  appointmentConstants.SAVE_DOCTOR_APPOINTMENT_TRANSFER_FULFILLED: {
            return {...state,loading:{...state.loading , saveDoctorTransfer:false},savedTransfer: true,updateTimeSlot :false,isDeletedAppointment:true,doctorTimeSlots:{},doctorTimeSlotAppointments:{}}
        }    
        case appointmentConstants.SAVE_DOCTOR_APPOINTMENT_TRANSFER_FAILURE:  {
            return {...state, loading:{...state.loading , saveDoctorTransfer:false},savedTransfer: false,updateTimeSlot :false,isDeletedAppointment:false,error:action.error}
        }
        case appointmentConstants.SAVE_DOCTOR_APPOINTMENT_TRANSFER_REJECTED:  {
            return {...state, loading:{...state.loading , saveDoctorTransfer:false},savedTransfer: false,updateTimeSlot :false,isDeletedAppointment:false,error:action.payload}
        }


        // Patient Delete Appointment set null in appointment time key
        case appointmentConstants.DELETE_PATIENT_APPOINTMENT_PENDING: {
            return {...state,loading:true,fetched: false,isDeletedAppointment:false,  user: action.payload}
        }  
        case  appointmentConstants.DELETE_PATIENT_APPOINTMENT_FULFILLED: {
            return {...state,
                doctorTimeSlotAppointments:{
                    ...state.doctorTimeSlotAppointments,
                    time_slots:{
                        ...state.doctorTimeSlotAppointments.time_slots,
                        [action.payload.time] : null,
                    }
                }
                ,loading: false,fetched: true,isDeletedAppointment:true}
        }    
        case appointmentConstants.DELETE_PATIENT_APPOINTMENT_FAILURE:  {
            return {...state, loading: false,fetched: false,isDeletedAppointment:false,error:action.error}
        }
        case appointmentConstants.DELETE_PATIENT_APPOINTMENT_REJECTED:  {
            return {...state, loading: false,fetched: false,isDeletedAppointment:false,error:action.payload}
        }


        //Save Appointment
        case appointmentConstants.SAVE_APPOINTMENT_PENDING: {
            return {...state,loadingSave:true,savedAppointment:false,fetched: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  appointmentConstants.SAVE_APPOINTMENT_FULFILLED: {
            return {...state,loadingSave: false,savedAppointment:true,fetched: false, appointment:action?.payload?.data?.data,doctorTimeSlots:{},doctorTimeSlotAppointments:{}}
        }    
        case appointmentConstants.SAVE_APPOINTMENT_FAILURE:  {
            return {...state, loadingSave: false,savedAppointment:false,fetched: false,isDeletedAppointment:false,error:action.error}
        }
        case appointmentConstants.SAVE_APPOINTMENT_REJECTED:  {
            return {...state, loadingSave: false,savedAppointment:false,fetched: false,isDeletedAppointment:false,error:action.payload}
        }
        //Save Appointment
        case appointmentConstants.UPDATE_APPOINTMENT_PENDING: {
            return {...state,loadingSave:true,savedAppointment:false,fetched: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  appointmentConstants.UPDATE_APPOINTMENT_FULFILLED: {
            return {...state,loadingSave: false,savedAppointment:true,fetched: false, appointment:action?.payload?.data?.data}
        }    
        case appointmentConstants.UPDATE_APPOINTMENT_FAILURE:  {
            return {...state, loadingSave: false,savedAppointment:false,fetched: false,isDeletedAppointment:false,error:action.error}
        }
        case appointmentConstants.UPDATE_APPOINTMENT_REJECTED:  {
            return {...state, loadingSave: false,savedAppointment:false,fetched: false,isDeletedAppointment:false,error:action.payload}
        }
        //Save Appointment
        case appointmentConstants.UPDATE_STATUS_APPOINTMENT_PENDING: {
            return {...state,loadingSave:true,savedAppointment:false,fetched: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  appointmentConstants.UPDATE_STATUS_APPOINTMENT_FULFILLED: {
            return {...state,loadingSave: false,savedAppointment:true,fetched: false, appointmentStatus:action?.payload?.data?.data}
        }    
        case appointmentConstants.UPDATE_STATUS_APPOINTMENT_FAILURE:  {
            return {...state, loadingSave: false,savedAppointment:false,fetched: false,isDeletedAppointment:false,error:action.error}
        }
        case appointmentConstants.UPDATE_STATUS_APPOINTMENT_REJECTED:  {
            return {...state, loadingSave: false,savedAppointment:false,fetched: false,isDeletedAppointment:false,error:action?.payload}
        }


        //Save reschedule  Appointment
        case appointmentConstants.PATIENT_SAVE_RESCHEDULE_APPOINTMENT_PENDING: {
            return {...state,loading:{...state.loading , reschedulePatientAppointment:true},saveReschedule:false,fetched: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  appointmentConstants.PATIENT_SAVE_RESCHEDULE_APPOINTMENT_FULFILLED: {
            return {...state,loading:{...state.loading , reschedulePatientAppointment:false},saveReschedule:true,fetched: false, appointment:action?.payload?.data?.data}
        }    
        case appointmentConstants.PATIENT_SAVE_RESCHEDULE_APPOINTMENT_FAILURE:  {
            return {...state, loading:{...state.loading , reschedulePatientAppointment:false},saveReschedule:false,fetched: false,isDeletedAppointment:false,error:action.error}
        }
        case appointmentConstants.PATIENT_SAVE_RESCHEDULE_APPOINTMENT_REJECTED:  {
            return {...state, loading:{...state.loading , reschedulePatientAppointment:false},saveReschedule:false,fetched: false,isDeletedAppointment:false,error:action.payload}
        }


        case appointmentConstants.GET_APPOINTMENT_PRINT_PENDING: {
            return {...state,loading:{...state.loading , getPrintAppointment:true},isDeletedAppointment:false, user: action.payload}
        }  
        case  appointmentConstants.GET_APPOINTMENT_PRINT_FULFILLED: {
            return {...state,loading:{...state.loading , getPrintAppointment:false},}
        }    
        case appointmentConstants.GET_APPOINTMENT_PRINT_FAILURE:  {
            return {...state, loading:{...state.loading , getPrintAppointment:false},isDeletedAppointment:false,error:action.error}
        }
        case appointmentConstants.GET_APPOINTMENT_PRINT_REJECTED:  {
            return {...state, loading:{...state.loading , getPrintAppointment:false},isDeletedAppointment:false,error:action.payload}
        }

        case appointmentConstants.GET_PATIENT_APPOINTMENT_CREATE_PENDING: {
            return {...state,loading:{...state.loading , getPatientAppointment:true},isDeletedAppointment:false, user: action.payload}
        }  
        case  appointmentConstants.GET_PATIENT_APPOINTMENT_CREATE_FULFILLED: {
            return {...state,loading:{...state.loading , getPatientAppointment:false},patientAppointments:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_PATIENT_APPOINTMENT_CREATE_FAILURE:  {
            return {...state, loading:{...state.loading , getPatientAppointment:false},isDeletedAppointment:false,error:action.error}
        }
        case appointmentConstants.GET_PATIENT_APPOINTMENT_CREATE_REJECTED:  {
            return {...state, loading:{...state.loading , getPatientAppointment:false},isDeletedAppointment:false,error:action.payload}
        }

        case appointmentConstants.UPDATE_DOCTOR_TIME_SLOT_STATUS_PENDING: {
            return {...state,loading:{...state.loading , updateDoctorTimeSlotStatus:true},savedAppointment:false,isDeletedAppointment:false, user: action.payload}
        }  
        case  appointmentConstants.UPDATE_DOCTOR_TIME_SLOT_STATUS_FULFILLED: {
            return {...state,loading:{...state.loading , updateDoctorTimeSlotStatus:false},savedAppointment:true,doctorTimeSlotAppointment:action?.payload?.data?.data}
        }    
        case appointmentConstants.UPDATE_DOCTOR_TIME_SLOT_STATUS_FAILURE:  {
            return {...state, loading:{...state.loading , updateDoctorTimeSlotStatus:false},savedAppointment:false,isDeletedAppointment:false,error:action.error}
        }
        case appointmentConstants.UPDATE_DOCTOR_TIME_SLOT_STATUS_REJECTED:  {
            return {...state, loading:{...state.loading , updateDoctorTimeSlotStatus:false},savedAppointment:false,isDeletedAppointment:false,error:action.payload}
        }

        case appointmentConstants.GET_DOCTOR_FEES_PENDING: {
            return {...state,loading:{...state.loading , getDoctorFee:true},isDeletedAppointment:false, user: action.payload}
        }  
        case  appointmentConstants.GET_DOCTOR_FEES_FULFILLED: {
            return {...state,loading:{...state.loading , getDoctorFee:false},doctorFee:action?.payload?.data?.data}
        }    
        case appointmentConstants.GET_DOCTOR_FEES_FAILURE:  {
            return {...state, loading:{...state.loading , getDoctorFee:false},isDeletedAppointment:false,error:action.error}
        }
        case appointmentConstants.GET_DOCTOR_FEES_REJECTED:  {
            return {...state, loading:{...state.loading , getDoctorFee:false},isDeletedAppointment:false,error:action.payload}
        }


        case appointmentConstants.SAVE_DOCTOR_APPOINTMENT_REFUND_PENDING: {
            return {...state,loading:{...state.loading , saveDoctorRefundFee:true},saveRefund:false,isDeletedAppointment:false, user: action.payload}
        }  
        case  appointmentConstants.SAVE_DOCTOR_APPOINTMENT_REFUND_FULFILLED: {
            return {...state,loading:{...state.loading , saveDoctorRefundFee:false},saveRefund:true,appointmentRefund:action?.payload?.data?.data}
        }    
        case appointmentConstants.SAVE_DOCTOR_APPOINTMENT_REFUND_FAILURE:  {
            return {...state, loading:{...state.loading , saveDoctorRefundFee:false},saveRefund:false,isDeletedAppointment:false,error:action.error}
        }
        case appointmentConstants.SAVE_DOCTOR_APPOINTMENT_REFUND_REJECTED:  {
            return {...state, loading:{...state.loading , saveDoctorRefundFee:false},saveRefund:false,isDeletedAppointment:false,error:action.payload}
        }


        case appointmentConstants.APPOINTMENT_SAVED_STATUS_CHANGE:  {
            return {...state, savedAppointment:false,error:{},savedTransfer:false,saveRefund:false,doctorTimeSlotAppointmentsReschedule:{}}
        }
        case appointmentConstants.APPOINTMENT_PATIENT_SELECT_REMOVE:  {
            return {...state, savedAppointment:false}
        }
        case appointmentConstants.APPOINTMENT_PATIENT_CLEAR:  {
            return {...state, patientAppointments:[]}
        }

        
        default :
            return state;
    }

}
