import { checkupConstants } from "./constants"

export default function checkupReducer(state={
    loading:false,
    fetched:false,
    saved:false,
    error: {},
    // doctorAvailability: {},
    checkupsCount: {},
    pendingAllocations: [],
    allocatedRooms: [],
    appointments: [],
    appointment: {},
    rooms: [],
    employees: [],
    patientVitalSigns: [],
    vitalSigns: [],
    checkup: {},
    checkupComplete: false,
    medicinesPrescription: [],
    servicesPrescription: [],
    imagesPrescription: [],

  }, action) {
      state= {...state,error:{} , dataSaved:{vitalSigns:false,saveMedicinePrescription:false},deletedMedicine:false,deletedService:false,checkupComplete:false}

    switch (action.type) {

        // Checkup status count pending and room allocated 
        case checkupConstants.GET_CHECK_UP_STATUS_COUNT_PENDING : {
            return {...state,loading:{...state.loading , checkupCountLoading:true},fetched: false,saved:false}
        }  
        case  checkupConstants.GET_CHECK_UP_STATUS_COUNT_FULFILLED: {
            return {...state,loading:{...state.loading , checkupCountLoading:false},fetched: true, checkupsCount:action?.payload?.data?.data}
        }    
        case checkupConstants.GET_CHECK_UP_STATUS_COUNT_FAILURE :  {
            return {...state, loading:{...state.loading , checkupCountLoading:false},error:action.error}
        }
        case checkupConstants.GET_CHECK_UP_STATUS_COUNT_REJECTED :  {
            return {...state, loading:{...state.loading , checkupCountLoading:false},error:action.payload}
        }
        // Checkup status pending  
        case checkupConstants.GET_CHECK_UP_PENDING_PENDING : {
            return {...state,loading:{...state.loading , checkupGetPendingLoading:true},fetched: false,saved:false}
        }  
        case  checkupConstants.GET_CHECK_UP_PENDING_FULFILLED: {
            return {...state,loading:{...state.loading , checkupGetPendingLoading:false},fetched: true, pendingRooms:action?.payload?.data?.data}
        }    
        case checkupConstants.GET_CHECK_UP_PENDING_FAILURE :  {
            return {...state, loading:{...state.loading , checkupGetPendingLoading:false},error:action.error}
        }
        case checkupConstants.GET_CHECK_UP_PENDING_REJECTED :  {
            return {...state, loading:{...state.loading , checkupGetPendingLoading:false},error:action.payload}
        }


        // Checkup get Rooms  
        case checkupConstants.GET_CHECK_UP_ROOMS_PENDING : {
            return {...state,loading:{...state.loading , checkupGetRoomLoading:true},fetched: false,saved:false}
        }  
        case  checkupConstants.GET_CHECK_UP_ROOMS_FULFILLED: {
            return {...state,loading:{...state.loading , checkupGetRoomLoading:false},fetched: true, rooms:action?.payload?.data}
        }    
        case checkupConstants.GET_CHECK_UP_ROOMS_FAILURE :  {
            return {...state, loading:{...state.loading , checkupGetRoomLoading:false},error:action.error}
        }
        case checkupConstants.GET_CHECK_UP_ROOMS_REJECTED :  {
            return {...state,loading:{...state.loading , checkupGetRoomLoading:false},error:action.payload}
        }


        // Checkup get Employees  
        case checkupConstants.GET_CHECK_UP_EMPLOYEES_PENDING : {
            return {...state,loading:{...state.loading , checkupGetEmployeeLoading:true},fetched: false,saved:false}
        }  
        case  checkupConstants.GET_CHECK_UP_EMPLOYEES_FULFILLED: {
            return {...state,loading:{...state.loading , checkupGetEmployeeLoading:false},fetched: true, employees:action?.payload?.data}
        }    
        case checkupConstants.GET_CHECK_UP_EMPLOYEES_FAILURE :  {
            return {...state, loading:{...state.loading , checkupGetEmployeeLoading:false},error:action.error}
        }
        case checkupConstants.GET_CHECK_UP_EMPLOYEES_REJECTED :  {
            return {...state,loading:{...state.loading , checkupGetEmployeeLoading:false},error:action.payload}
        }


        // Room allocated 
        case checkupConstants.GET_CHECK_UP_ROOM_ALLOCATED_PENDING : {
            return {...state,loading:{...state.loading , checkupGetRoomAllocationLoading:true},fetched: false,saved:false}
        }  
        case  checkupConstants.GET_CHECK_UP_ROOM_ALLOCATED_FULFILLED: {
            return {...state,loading:{...state.loading , checkupGetRoomAllocationLoading:false},fetched: true,allocatedRooms:action.payload?.data?.data}
        }    
        case checkupConstants.GET_CHECK_UP_ROOM_ALLOCATED_FAILURE :  {
            return {...state,loading:{...state.loading , checkupGetRoomAllocationLoading:false},error:action.error,saved:false}
        }
        case checkupConstants.GET_CHECK_UP_ROOM_ALLOCATED_REJECTED :  {
            return {...state, loading:{...state.loading , checkupGetRoomAllocationLoading:false},error:action.payload}
        }
       
        // Room allocated 
        case checkupConstants.GET_CHECK_UP_FINISHED_PENDING : {
            return {...state,loading:{...state.loading , checkupGetFinishedLoading:true},fetched: false,saved:false}
        }  
        case  checkupConstants.GET_CHECK_UP_FINISHED_FULFILLED: {
            return {...state,loading:{...state.loading , checkupGetFinishedLoading:false},fetched: true,finishedCheckups:action.payload?.data?.data}
        }    
        case checkupConstants.GET_CHECK_UP_FINISHED_FAILURE :  {
            return {...state,loading:{...state.loading , checkupGetFinishedLoading:false},error:action.error,saved:false}
        }
        case checkupConstants.GET_CHECK_UP_FINISHED_REJECTED :  {
            return {...state, loading:{...state.loading , checkupGetFinishedLoading:false},error:action.payload}
        }
       
        // Room allocation save  
        case checkupConstants.SAVE_CHECK_UP_ROOM_ALLOCATIONS_PENDING : {
            return {...state,loading:{...state.loading , checkupSaveRoomAllocationLoading:true},fetched: false,saved:false}
        }  
        case  checkupConstants.SAVE_CHECK_UP_ROOM_ALLOCATIONS_FULFILLED: {
            return {...state,loading:{...state.loading , checkupSaveRoomAllocationLoading:false},fetched: true,saved:true,roomAllocation:action.payload?.data?.data}
        }    
        case checkupConstants.SAVE_CHECK_UP_ROOM_ALLOCATIONS_FAILURE :  {
            return {...state, loading:{...state.loading , checkupSaveRoomAllocationLoading:false},error:action.error,saved:false}
        }
        case checkupConstants.SAVE_CHECK_UP_ROOM_ALLOCATIONS_REJECTED :  {
            return {...state, loading:{...state.loading , checkupSaveRoomAllocationLoading:false},error:action.payload}
        }
       
        //GET Room Allocation Basic info
        case checkupConstants.GET_CHECK_UP_ROOM_BASIC_INFO_PENDING: {
            return {...state,loading:true,fetched: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  checkupConstants.GET_CHECK_UP_ROOM_BASIC_INFO_FULFILLED: {
            return {...state,loading: false,fetched: true, checkupRoomAllocationBasic:action?.payload?.data?.data}
        }    
        case checkupConstants.GET_CHECK_UP_ROOM_BASIC_INFO_FAILURE:  {
            return {...state, loading: false,fetched: false , error:action.error}
        }
        case checkupConstants.GET_CHECK_UP_ROOM_BASIC_INFO_REJECTED:  {
            return {...state, loading: false,fetched: false,error:action.payload}
        }


        //GET Room Allocation Basic info
        case checkupConstants.GET_CHECK_UP_VITAL_SIGNS_FOR_PATIENT_PENDING: {
            return {...state,loading:true,fetched: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  checkupConstants.GET_CHECK_UP_VITAL_SIGNS_FOR_PATIENT_FULFILLED: {
            return {...state,loading: false,fetched: true, patientVitalSigns:action?.payload?.data?.data}
        }    
        case checkupConstants.GET_CHECK_UP_VITAL_SIGNS_FOR_PATIENT_FAILURE:  {
            return {...state, loading: false,fetched: false , error:action.error}
        }
        case checkupConstants.GET_CHECK_UP_VITAL_SIGNS_FOR_PATIENT_REJECTED:  {
            return {...state, loading: false,fetched: false,error:action.payload}
        }

        //GET Room Allocation Basic info
        case checkupConstants.GET_CHECK_UP_VITAL_SIGNS_ALL_PENDING: {
            return {...state,loading:true,fetched: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  checkupConstants.GET_CHECK_UP_VITAL_SIGNS_ALL_FULFILLED: {
            return {...state,loading: false,fetched: true, vitalSigns:action?.payload?.data?.data}
        }    
        case checkupConstants.GET_CHECK_UP_VITAL_SIGNS_ALL_FAILURE:  {
            return {...state, loading: false,fetched: false , error:action.error}
        }
        case checkupConstants.GET_CHECK_UP_VITAL_SIGNS_ALL_REJECTED:  {
            return {...state, loading: false,fetched: false,error:action.payload}
        }

        //GET Room Allocation Basic info
        case checkupConstants.GET_CHECK_UP_ALL_APPOINTMENTS_PENDING: {
            return {...state,loading:true,fetched: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  checkupConstants.GET_CHECK_UP_ALL_APPOINTMENTS_FULFILLED: {
            return {...state,loading: false,fetched: true, appointments:action?.payload?.data?.data}
        }    
        case checkupConstants.GET_CHECK_UP_ALL_APPOINTMENTS_FAILURE:  {
            return {...state, loading: false,fetched: false , error:action.error}
        }
        case checkupConstants.GET_CHECK_UP_ALL_APPOINTMENTS_REJECTED:  {
            return {...state, loading: false,fetched: false,error:action.payload}
        }

        //GET Room Allocation Basic info
        case checkupConstants.GET_CHECK_UP_ONE_APPOINTMENTS_PENDING: {
            return {...state,loading:{...state.loading , getCheckupOneAppointment:true},fetched: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  checkupConstants.GET_CHECK_UP_ONE_APPOINTMENTS_FULFILLED: {
            return {...state,loading:{...state.loading , getCheckupOneAppointment:false},fetched: true, appointment:action?.payload?.data?.data}
        }    
        case checkupConstants.GET_CHECK_UP_ONE_APPOINTMENTS_FAILURE:  {
            return {...state, loading:{...state.loading , getCheckupOneAppointment:false},fetched: false , error:action.error}
        }
        case checkupConstants.GET_CHECK_UP_ONE_APPOINTMENTS_REJECTED:  {
            return {...state, loading:{...state.loading , getCheckupOneAppointment:false},fetched: false,error:action.payload}
        }

        //GET Room Allocation Basic info
        case checkupConstants.GET_MEDICINES_PENDING: {
            return {...state,loading:{...state.loading , getMedicines:true},fetched: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  checkupConstants.GET_MEDICINES_FULFILLED: {
            return {...state,loading:{...state.loading , getMedicines:false},fetched: true, medicines:action?.payload?.data?.data}
        }    
        case checkupConstants.GET_MEDICINES_FAILURE:  {
            return {...state, loading:{...state.loading , getMedicines:false},fetched: false , error:action.error}
        }
        case checkupConstants.GET_MEDICINES_REJECTED:  {
            return {...state, loading:{...state.loading , getMedicines:false},fetched: false,error:action.payload}
        }


        case checkupConstants.GET_CHECK_UP_PRESCRIPTION_PENDING: {
            return {...state,loading:{...state.loading , getCheckupPrescription:true},fetched: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  checkupConstants.GET_CHECK_UP_PRESCRIPTION_FULFILLED: {
            return {...state,loading:{...state.loading , getCheckupPrescription:false},fetched: true, medicinesPrescription:action?.payload?.data?.data?.medicines , servicesPrescription:action?.payload?.data?.data?.service_types , imagesPrescription:action?.payload?.data?.data?.documents}
        }    
        case checkupConstants.GET_CHECK_UP_PRESCRIPTION_FAILURE:  {
            return {...state, loading:{...state.loading , getCheckupPrescription:false},fetched: false , error:action.error}
        }
        case checkupConstants.GET_CHECK_UP_PRESCRIPTION_REJECTED:  {
            return {...state, loading:{...state.loading , getCheckupPrescription:false},fetched: false,error:action.payload}
        }


        case checkupConstants.GET_SERVICES_PENDING: {
            return {...state,loading:{...state.loading , getServices:true},fetched: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  checkupConstants.GET_SERVICES_FULFILLED: {
            return {...state,loading:{...state.loading , getServices:false},fetched: true, services:action?.payload?.data?.data}
        }    
        case checkupConstants.GET_SERVICES_FAILURE:  {
            return {...state, loading:{...state.loading , getServices:false},fetched: false , error:action.error}
        }
        case checkupConstants.GET_SERVICES_REJECTED:  {
            return {...state, loading:{...state.loading , getServices:false},fetched: false,error:action.payload}
        }

        case checkupConstants.DELETE_CHECK_UP_PRESCRIPTION_MEDICINE_PENDING: {
            return {...state,loading:{...state.loading , deleteMedicine:true},deletedMedicine: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  checkupConstants.DELETE_CHECK_UP_PRESCRIPTION_MEDICINE_FULFILLED: {
            return {...state,loading:{...state.loading , deleteMedicine:false},deletedMedicine: true}
        }    
        case checkupConstants.DELETE_CHECK_UP_PRESCRIPTION_MEDICINE_FAILURE:  {
            return {...state, loading:{...state.loading , deleteMedicine:false},deletedMedicine: false , error:action.error}
        }
        case checkupConstants.DELETE_CHECK_UP_PRESCRIPTION_MEDICINE_REJECTED:  {
            return {...state, loading:{...state.loading , deleteMedicine:false},deletedMedicine: false,error:action.payload}
        }
        case checkupConstants.DELETE_CHECK_UP_PRESCRIPTION_SERVICE_PENDING: {
            return {...state,loading:{...state.loading , deleteService:true},deletedService: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  checkupConstants.DELETE_CHECK_UP_PRESCRIPTION_SERVICE_FULFILLED: {
            return {...state,loading:{...state.loading , deleteService:false},deletedService: true}
        }    
        case checkupConstants.DELETE_CHECK_UP_PRESCRIPTION_SERVICE_FAILURE:  {
            return {...state, loading:{...state.loading , deleteService:false},deletedService: false , error:action.error}
        }
        case checkupConstants.DELETE_CHECK_UP_PRESCRIPTION_SERVICE_REJECTED:  {
            return {...state, loading:{...state.loading , deleteService:false},deletedService: false,error:action.payload}
        }


        case checkupConstants.GET_DOSAGE_TEMPLATE_PENDING: {
            return {...state,loading:{...state.loading , getDosageTemplate:true},fetched: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  checkupConstants.GET_DOSAGE_TEMPLATE_FULFILLED: {
            return {...state,loading:{...state.loading , getDosageTemplate:false},fetched: true, dosageTemplates:action?.payload?.data?.data}
        }    
        case checkupConstants.GET_DOSAGE_TEMPLATE_FAILURE:  {
            return {...state, loading:{...state.loading , getDosageTemplate:false},fetched: false , error:action.error}
        }
        case checkupConstants.GET_DOSAGE_TEMPLATE_REJECTED:  {
            return {...state, loading:{...state.loading , getDosageTemplate:false},fetched: false,error:action.payload}
        }


        case checkupConstants.SAVE_CHECK_UP_MEDICINE_PRESCRIPTIONS_PENDING: {
            return {...state,loading:{...state.loading , saveMedicinePrescription:true} , dataSaved:{...state.dataSaved ,saveMedicinePrescription:false },fetched: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  checkupConstants.SAVE_CHECK_UP_MEDICINE_PRESCRIPTIONS_FULFILLED: {
            return {...state,loading:{...state.loading , saveMedicinePrescription:false} , dataSaved:{...state.dataSaved ,saveMedicinePrescription:true },fetched: true, medicinesPrescription:[...state.medicinesPrescription,action?.payload?.data?.data]}
        }    
        case checkupConstants.SAVE_CHECK_UP_MEDICINE_PRESCRIPTIONS_FAILURE:  {
            return {...state, loading:{...state.loading , saveMedicinePrescription:false} , dataSaved:{...state.dataSaved ,saveMedicinePrescription:false },fetched: false , error:action.error}
        }
        case checkupConstants.SAVE_CHECK_UP_MEDICINE_PRESCRIPTIONS_REJECTED:  {
            return {...state, loading:{...state.loading , saveMedicinePrescription:false} , dataSaved:{...state.dataSaved ,saveMedicinePrescription:false },fetched: false,error:action.payload}
        }

        case checkupConstants.SAVE_CHECK_UP_IMAGES_PRESCRIPTIONS_PENDING: {
            return {...state,loading:{...state.loading , saveImagePrescription:true} , dataSaved:{...state.dataSaved ,saveImagePrescription:false },fetched: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  checkupConstants.SAVE_CHECK_UP_IMAGES_PRESCRIPTIONS_FULFILLED: {
            return {...state,loading:{...state.loading , saveImagePrescription:false} , dataSaved:{...state.dataSaved ,saveImagePrescription:true },fetched: true, imagesPrescription:[...state.imagesPrescription,action?.payload?.data?.data]}
        }    
        case checkupConstants.SAVE_CHECK_UP_IMAGES_PRESCRIPTIONS_FAILURE:  {
            return {...state, loading:{...state.loading , saveImagePrescription:false} , dataSaved:{...state.dataSaved ,saveImagePrescription:false },fetched: false , error:action.error}
        }
        case checkupConstants.SAVE_CHECK_UP_IMAGES_PRESCRIPTIONS_REJECTED:  {
            return {...state, loading:{...state.loading , saveImagePrescription:false} , dataSaved:{...state.dataSaved ,saveImagePrescription:false },fetched: false,error:action.payload}
        }


        case checkupConstants.SAVE_CHECK_UP_SERVICE_PRESCRIPTIONS_PENDING: {
            return {...state,loading:{...state.loading , saveServicePrescription:true} , dataSaved:{...state.dataSaved ,saveServicePrescription:false },fetched: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  checkupConstants.SAVE_CHECK_UP_SERVICE_PRESCRIPTIONS_FULFILLED: {
            return {...state,loading:{...state.loading , saveServicePrescription:false} , dataSaved:{...state.dataSaved ,saveServicePrescription:true },fetched: true, servicesPrescription:[...state.servicesPrescription,action?.payload?.data?.data]}
        }    
        case checkupConstants.SAVE_CHECK_UP_SERVICE_PRESCRIPTIONS_FAILURE:  {
            return {...state, loading:{...state.loading , saveServicePrescription:false} , dataSaved:{...state.dataSaved ,saveServicePrescription:false },fetched: false , error:action.error}
        }
        case checkupConstants.SAVE_CHECK_UP_SERVICE_PRESCRIPTIONS_REJECTED:  {
            return {...state, loading:{...state.loading , saveServicePrescription:false} , dataSaved:{...state.dataSaved ,saveServicePrescription:false },fetched: false,error:action.payload}
        }


        //GET Room Allocation Basic info
        case checkupConstants.START_CHECK_UP_ONE_APPOINTMENTS_PENDING: {
            return {...state,loading:true,fetched: false,checkupStart: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  checkupConstants.START_CHECK_UP_ONE_APPOINTMENTS_FULFILLED: {
            return {...state,loading: false,fetched: true,checkupStart: true, startCheckup:action?.payload?.data?.data}
        }    
        case checkupConstants.START_CHECK_UP_ONE_APPOINTMENTS_FAILURE:  {
            return {...state, loading: false,fetched: false ,checkupStart: false, error:action.error}
        }
        case checkupConstants.START_CHECK_UP_ONE_APPOINTMENTS_REJECTED:  {
            return {...state, loading: false,fetched: false,checkupStart: false,error:action.payload}
        }
        //GET Room Allocation Basic info
        case checkupConstants.END_CHECK_UP_ONE_APPOINTMENTS_PENDING: {
            return {...state,loading:true,fetched: false,checkupComplete: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  checkupConstants.END_CHECK_UP_ONE_APPOINTMENTS_FULFILLED: {
            return {...state,loading: false,fetched: true, checkupComplete: true,appointment:action?.payload?.data?.data}
        }    
        case checkupConstants.END_CHECK_UP_ONE_APPOINTMENTS_FAILURE:  {
            return {...state, loading: false,fetched: false ,checkupComplete: false, error:action.error}
        }
        case checkupConstants.END_CHECK_UP_ONE_APPOINTMENTS_REJECTED:  {
            return {...state, loading: false,fetched: false,checkupComplete: false,error:action.payload}
        }
        case checkupConstants.VITAL_SIGNS_SAVE_PATIENT_PENDING: {
            return {...state,loading:{...state.loading , vitalSignSave:true} , dataSaved:{...state.dataSaved , vitalSigns: false},fetched: false,checkupComplete: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  checkupConstants.VITAL_SIGNS_SAVE_PATIENT_FULFILLED: {
            return {...state,loading:{...state.loading , vitalSignSave:false} , dataSaved:{...state.dataSaved , vitalSigns: true},fetched: true, checkupComplete: true,appointment:action?.payload?.data?.data}
        }    
        case checkupConstants.VITAL_SIGNS_SAVE_PATIENT_FAILURE:  {
            return {...state, loading:{...state.loading , vitalSignSave:false} , dataSaved:{...state.dataSaved , vitalSigns: false},fetched: false ,checkupComplete: false, error:action.error}
        }
        case checkupConstants.VITAL_SIGNS_SAVE_PATIENT_REJECTED:  {
            return {...state, loading:{...state.loading , vitalSignSave:false} , dataSaved:{...state.dataSaved , vitalSigns: false},fetched: false,checkupComplete: false,error:action.payload}
        }
        case checkupConstants.CHECKUP_SAVED_STATUS_CHANGE:  {
            return {...state, loading:{...state.loading , vitalSignSave:false} , dataSaved:{...state.dataSaved , vitalSigns: false},fetched: false,checkupComplete: false,error:{}}
        }

        default :
            return state;
    }

}
