import { homeConstants } from "./constants"

export default function homeReducer(state={
    loading:false,
    menuLoading:false,
    fetched:false,
    postfetched:false,
    specialization: {},
    doctor:{},
    doctorSchedule:{},
    schedule:{},
    availablities:[],
    timeSlotData:{},
    timeSlots:[],
    doctorAvailabilities:[]
  }, action) {

    switch (action.type) {

        case homeConstants.GET_SPECIALIZATION_PENDING : {
            return {...state,menuLoading:true,fetched:false, user: action.payload}
        }  
        case  homeConstants.GET_SPECIALIZATION_FULFILLED: {
            return {...state,menuLoading: false,fetched: true, specialization:action?.payload?.data?.data}
        }    
        case homeConstants.GET_SPECIALIZATION_FAILURE :  {
            return {...state, menuLoading: false,fetched:false,error:action.error}
        }


        case homeConstants.GET_SPECIALIZED_DOCTOR_PENDING : {
            return {...state,menuLoading:true,fetched:false, user: action.payload}
        }  
        case  homeConstants.GET_SPECIALIZED_DOCTOR_FULFILLED: {
            return {...state,menuLoading: false,fetched: false, doctor:action?.payload?.data?.data}
        }    
        case homeConstants.GET_SPECIALIZED_DOCTOR_FAILURE :  {
            return {...state, menuLoading: false,fetched:false,error:action.error}
        }
        
        case homeConstants.GET_SEARCHED_DOCTOR_PENDING : {
            return {...state,menuLoading:true, fetched:false,user: action.payload}
        }  
        case  homeConstants.GET_SEARCHED_DOCTOR_FULFILLED: {
            return {...state,menuLoading: false,fetched: true, doctor:action?.payload?.data?.data}
        }    
        case homeConstants.GET_SEARCHED_DOCTOR_FAILURE :  {
            return {...state, menuLoading: false,fetched:false,error:action.error}
        }

        case homeConstants.GET_SPECIALIZED_DOCTOR_SCHEDULE_PENDING : {
            return {...state,loading:true, fetched:false,user: action.payload}
        }  
        case  homeConstants.GET_SPECIALIZED_DOCTOR_SCHEDULE_FULFILLED: {
            return {...state,loading: false,fetched: true, schedule:action?.payload?.data?.data}
        }    
        case homeConstants.GET_SPECIALIZED_DOCTOR_SCHEDULE_FAILURE :  {
            return {...state, loading: false,fetched:false,error:action.error}
        }
        
        case homeConstants.GET_SPECIALIZED_SCHEDULE_PENDING : {
            return {...state,loading:true, fetched:false,user: action.payload}
        }  
        case  homeConstants.GET_SPECIALIZED_SCHEDULE_FULFILLED: {
            return {...state,loading: false,fetched: true, schedule:action?.payload?.data?.data}
        }    
        case homeConstants.GET_SPECIALIZED_SCHEDULE_FAILURE :  {
            return {...state, loading: false,fetched:false,error:action.error}
        }

        case homeConstants.GET_SPECIALIZED_DOCTOR_APPOINTMENTS_PENDING : {
            return {...state,loading:true,fetched:false, user: action.payload}
        }  
        case  homeConstants.GET_SPECIALIZED_DOCTOR_APPOINTMENTS_FULFILLED: {
            return {...state,loading: false,fetched: true, appointments:action?.payload?.data?.data}
        }    
        case homeConstants.GET_SPECIALIZED_DOCTOR_APPOINTMENTS_FAILURE :  {
            return {...state, loading: false,fetched:false,error:action.error}
        }

        case homeConstants.GET_APPOINTMENTS_PENDING : {
            return {...state,loading:true,fetched:false, user: action.payload}
        }  
        case  homeConstants.GET_APPOINTMENTS_FULFILLED: {
            return {...state,loading: false,fetched: true, appointments:action?.payload?.data?.data}
        }    
        case homeConstants.GET_APPOINTMENTS_FAILURE :  {
            return {...state, loading: false,fetched:false,error:action.error}
        }
        case homeConstants.GET_SEARCH_HOME_APPOINTMENTS_PENDING : {
            return {...state,loading:true,fetched:false, user: action.payload}
        }  
        case  homeConstants.GET_SEARCH_HOME_APPOINTMENTS_FULFILLED: {
            return {...state,loading: false,fetched: true, appointments:action?.payload?.data?.data}
        }    
        case homeConstants.GET_SEARCH_HOME_APPOINTMENTS_FAILURE :  {
            return {...state, loading: false,fetched:false,error:action.error}
        }
        


        case homeConstants.GET_DOCTORS_AVAILABLITIES_PENDING : {
            return {...state,loading:true,fetched:false, user: action.payload}
        }  
        case  homeConstants.GET_DOCTORS_AVAILABLITIES_FULFILLED: {
            return {...state,loading: false,fetched: true, availablities:action?.payload?.data?.data}
        }    
        case homeConstants.GET_DOCTORS_AVAILABLITIES_FAILURE :  {
            return {...state, loading: false,fetched:false,error:action.error}
        }

        case homeConstants. GET_DOCOTORS_APPOINTMENTS_FOR_TIMESLOT_PENDING : {
            return {...state,loading:true, fetched:false,user: action.payload}
        }  
        case  homeConstants.GET_DOCOTORS_APPOINTMENTS_FOR_TIMESLOT_FULFILLED: {
            return {...state,loading: false,fetched: true, timeSlotData:action?.payload?.data?.data}
        }    
        case homeConstants.GET_DOCOTORS_APPOINTMENTS_FOR_TIMESLOT_FAILURE :  {
            return {...state, loading: false,error:action.error}
        }


        case homeConstants. GET_DOCOTORS_CALENDAR_PENDING : {
            return {...state,loading:true,fetched:false, user: action.payload}
        }  
        case  homeConstants.GET_DOCOTORS_CALENDAR_FULFILLED: {
            return {...state,
                    loading: false,
                    fetched: true, 
                    timeSlots:action?.payload?.data?.data?.time_slots, 
                    leave_time_slots:action?.payload?.data?.data?.leave_time_slots, 
                    doctorAvailabilities:action?.payload?.data?.data?.availabilities,
                    doctorFeeDetails:action?.payload?.data?.data?.doctor_fees_details 
                }
        }    
        case homeConstants.GET_DOCOTORS_CALENDAR_FAILURE :  {
            return {...state, loading: false,fetched:false,error:action.error}
        }


        case homeConstants. CONFIRMED_DOCTOR_TIMESLOT_BY_AVALAIBLITY_PENDING : {
            return {...state,loading:true,postfetched:false, user: action.payload}
        }  
        case  homeConstants.CONFIRMED_DOCTOR_TIMESLOT_BY_AVALAIBLITY_FULFILLED: {
            return {...state,loading: false,postfetched: true, timeSlotData:action?.payload?.data?.data }
        }    
        case homeConstants.CONFIRMED_DOCTOR_TIMESLOT_BY_AVALAIBLITY_FAILURE :  {
            return {...state, loading: false,postfetched:false,error:action.error}
        }

        //GET Doctor Time Slots Appointment for doctor id 
        case homeConstants.GET_DOCTOR_TIME_SLOT_APPOINTMENTS_PENDING: {
            return {...state,loading:true,fetched: false,doctorTimeSlotAppointments: [], isDeletedAppointment:false, user: action.payload}
        }  
        case  homeConstants.GET_DOCTOR_TIME_SLOT_APPOINTMENTS_FULFILLED: {
            return {...state,loading: false,fetched: false, doctorTimeSlotAppointments:action?.payload?.data?.data}
        }    
        case homeConstants.GET_DOCTOR_TIME_SLOT_APPOINTMENTS_FAILURE:  {
            return {...state, loading: false,fetched: false,isDeletedAppointment:false,error:action.error}
        }
        case homeConstants.GET_DOCTOR_TIME_SLOT_APPOINTMENTS_REJECTED:  {
            return {...state, loading: false,fetched: false,isDeletedAppointment:false,error:action.error}
        }

          //GET Doctor Time Slots for doctor id 
        case homeConstants.GET_DOCTOR_TIME_SLOTS_PENDING: {
            return {...state,loading:true,fetched: false,isDeletedAppointment:false, user: action.payload}
        }  
        case  homeConstants.GET_DOCTOR_TIME_SLOTS_FULFILLED: {
            return {...state,loading: false,fetched: false, doctorTimeSlots:action?.payload?.data?.data}
        }    
        case homeConstants.GET_DOCTOR_TIME_SLOTS_FAILURE:  {
            return {...state, loading: false,fetched: false,isDeletedAppointment:false,error:action.error}
        }
        case homeConstants.GET_DOCTOR_TIME_SLOTS_REJECTED:  {
            return {...state, loading: false,fetched: false,isDeletedAppointment:false,error:action.error}
        }
        default :
            return state;
    }

}
