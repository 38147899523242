export const patientConstants = {


    GET_PATIENTS : 'GET_PATIENTS',
    GET_PATIENTS_PENDING : 'GET_PATIENTS_PENDING',
    GET_PATIENTS_FULFILLED : 'GET_PATIENTS_FULFILLED',
    GET_PATIENTS_FAILURE : 'GET_PATIENTS_FAILURE',
    GET_PATIENTS_REJECTED : 'GET_PATIENTS_REJECTED',

    GET_OLD_PATIENTS : 'GET_OLD_PATIENTS',
    GET_OLD_PATIENTS_PENDING : 'GET_OLD_PATIENTS_PENDING',
    GET_OLD_PATIENTS_FULFILLED : 'GET_OLD_PATIENTS_FULFILLED',
    GET_OLD_PATIENTS_FAILURE : 'GET_OLD_PATIENTS_FAILURE',
    GET_OLD_PATIENTS_REJECTED : 'GET_OLD_PATIENTS_REJECTED',

    GET_EXCEL_PATIENTS : 'GET_EXCEL_PATIENTS',
    GET_EXCEL_PATIENTS_PENDING : 'GET_EXCEL_PATIENTS_PENDING',
    GET_EXCEL_PATIENTS_FULFILLED : 'GET_EXCEL_PATIENTS_FULFILLED',
    GET_EXCEL_PATIENTS_FAILURE : 'GET_EXCEL_PATIENTS_FAILURE',
    GET_EXCEL_PATIENTS_REJECTED : 'GET_EXCEL_PATIENTS_REJECTED',

    GET_ONE_PATIENTS : 'GET_ONE_PATIENTS',
    GET_ONE_PATIENTS_PENDING : 'GET_ONE_PATIENTS_PENDING',
    GET_ONE_PATIENTS_FULFILLED : 'GET_ONE_PATIENTS_FULFILLED',
    GET_ONE_PATIENTS_FAILURE : 'GET_ONE_PATIENTS_FAILURE',
    GET_ONE_PATIENTS_REJECTED : 'GET_ONE_PATIENTS_REJECTED',

    GET_ONE_FOR_EDIT_PATIENTS : 'GET_ONE_FOR_EDIT_PATIENTS',
    GET_ONE_FOR_EDIT_PATIENTS_PENDING : 'GET_ONE_FOR_EDIT_PATIENTS_PENDING',
    GET_ONE_FOR_EDIT_PATIENTS_FULFILLED : 'GET_ONE_FOR_EDIT_PATIENTS_FULFILLED',
    GET_ONE_FOR_EDIT_PATIENTS_FAILURE : 'GET_ONE_FOR_EDIT_PATIENTS_FAILURE',
    GET_ONE_FOR_EDIT_PATIENTS_REJECTED : 'GET_ONE_FOR_EDIT_PATIENTS_REJECTED',

    GET_SECTION_PATIENTS : 'GET_SECTION_PATIENTS',
    GET_SECTION_PATIENTS_PENDING : 'GET_SECTION_PATIENTS_PENDING',
    GET_SECTION_PATIENTS_FULFILLED : 'GET_SECTION_PATIENTS_FULFILLED',
    GET_SECTION_PATIENTS_FAILURE : 'GET_SECTION_PATIENTS_FAILURE',
    GET_SECTION_PATIENTS_REJECTED : 'GET_SECTION_PATIENTS_REJECTED',

    GET_PATIENTS_CHECKUPS : 'GET_PATIENTS_CHECKUPS',
    GET_PATIENTS_CHECKUPS_PENDING : 'GET_PATIENTS_CHECKUPS_PENDING',
    GET_PATIENTS_CHECKUPS_FULFILLED : 'GET_PATIENTS_CHECKUPS_FULFILLED',
    GET_PATIENTS_CHECKUPS_FAILURE : 'GET_PATIENTS_CHECKUPS_FAILURE',
    GET_PATIENTS_CHECKUPS_REJECTED : 'GET_PATIENTS_CHECKUPS_REJECTED',

    // Add Patient

    GET_SEARCH : 'GET_SEARCH',
    GET_SEARCH_PENDING : 'GET_SEARCH_PENDING',
    GET_SEARCH_FULFILLED : 'GET_SEARCH_FULFILLED',
    GET_SEARCH_FAILURE : 'GET_SEARCH_FAILURE',
    GET_SEARCH_REJECTED : 'GET_SEARCH_REJECTED',



    GET_PATIENT_TYPE : 'GET_PATIENT_TYPE',
    GET_PATIENT_TYPE_PENDING : 'GET_PATIENT_TYPE_PENDING',
    GET_PATIENT_TYPE_FULFILLED : 'GET_PATIENT_TYPE_FULFILLED',
    GET_PATIENT_TYPE_FAILURE : 'GET_PATIENT_TYPE_FAILURE',
    GET_PATIENT_TYPE_REJECTED : 'GET_PATIENT_TYPE_REJECTED',


    GET_DOCTORS : 'GET_DOCTORS',
    GET_DOCTORS_PENDING : 'GET_DOCTORS_PENDING',
    GET_DOCTORS_FULFILLED : 'GET_DOCTORS_FULFILLED',
    GET_DOCTORS_FAILURE : 'GET_DOCTORS_FAILURE',
    GET_DOCTORS_REJECTED : 'GET_DOCTORS_REJECTED',


    GET_PARTNERS : 'GET_PARTNERS',
    GET_PARTNERS_PENDING : 'GET_PARTNERS_PENDING',
    GET_PARTNERS_FULFILLED : 'GET_PARTNERS_FULFILLED',
    GET_PARTNERS_FAILURE : 'GET_PARTNERS_FAILURE',
    GET_PARTNERS_REJECTED : 'GET_PARTNERS_REJECTED',


    SAVE_PATIENTS : 'SAVE_PATIENTS',
    SAVE_PATIENTS_PENDING : 'SAVE_PATIENTS_PENDING',
    SAVE_PATIENTS_FULFILLED : 'SAVE_PATIENTS_FULFILLED',
    SAVE_PATIENTS_FAILURE : 'SAVE_PATIENTS_FAILURE',
    SAVE_PATIENTS_REJECTED : 'SAVE_PATIENTS_REJECTED',

    SAVE_GUARDIANS : 'SAVE_GUARDIANS',
    SAVE_GUARDIANS_PENDING : 'SAVE_GUARDIANS_PENDING',
    SAVE_GUARDIANS_FULFILLED : 'SAVE_GUARDIANS_FULFILLED',
    SAVE_GUARDIANS_FAILURE : 'SAVE_GUARDIANS_FAILURE',
    SAVE_GUARDIANS_REJECTED : 'SAVE_GUARDIANS_REJECTED',


    EDIT_PATIENTS : 'EDIT_PATIENTS',
    EDIT_PATIENTS_PENDING : 'EDIT_PATIENTS_PENDING',
    EDIT_PATIENTS_FULFILLED : 'EDIT_PATIENTS_FULFILLED',
    EDIT_PATIENTS_FAILURE : 'EDIT_PATIENTS_FAILURE',
    EDIT_PATIENTS_REJECTED : 'EDIT_PATIENTS_REJECTED',

    EDIT_GUARDIAN : 'EDIT_GUARDIAN',
    EDIT_GUARDIAN_PENDING : 'EDIT_GUARDIAN_PENDING',
    EDIT_GUARDIAN_FULFILLED : 'EDIT_GUARDIAN_FULFILLED',
    EDIT_GUARDIAN_FAILURE : 'EDIT_GUARDIAN_FAILURE',
    EDIT_GUARDIAN_REJECTED : 'EDIT_GUARDIAN_REJECTED',


    CREATE_AN_APPOINTMENT : 'CREATE_AN_APPOINTMENT',
    CREATE_AN_APPOINTMENT_PENDING : 'CREATE_AN_APPOINTMENT_PENDING',
    CREATE_AN_APPOINTMENT_FULFILLED : 'CREATE_AN_APPOINTMENT_FULFILLED',
    CREATE_AN_APPOINTMENT_FAILURE : 'CREATE_AN_APPOINTMENT_FAILURE',
    CREATE_AN_APPOINTMENT_REJECTED : 'CREATE_AN_APPOINTMENT_REJECTED',


    PATIENT_OR_GUARDIAN_SAVED_STATUS_CHANGE : 'PATIENT_OR_GUARDIAN_SAVED_STATUS_CHANGE',
};
