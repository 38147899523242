export default function reducer(state={
    user: {},
    fetching: false,
    fetched: false,
    fetchedError:false,
    error: null,
  }, action) {

    switch (action.type) {

        case 'SET_USER': {
            return {...state, user: action.payload}
        }  
        case 'CLEAR_USER': {
            return {...state,fetching: false,fetched: false,   fetchedError:false, user:{}}
        }    
        case "LOGIN_USER_PENDING": {
            return {...state, fetching: true}
        }
        case "LOGIN_USER_REJECTED": {
            
            return {...state, fetching: false, fetchedError: true,error: action.payload}
        }
        case "LOGIN_USER_FULFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true,
                user: action.payload.data,
            }
        }
        
        default :
            return state;
    }

}
