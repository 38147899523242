export const messagesEnum = {
    SUCCESS : "",
    ERROR : "Error",

    // Appointment
    APPOINTMENT_SAVED_SUCCESSFULLY:'Appointment saved successfully' ,
    APPOINTMENT_UPDATED_SUCCESSFULLY:'Appointment updated successfully' ,
    PLEASE_SELECT_CHECKUP_START_APPOINTMENT:'Please select checkup start or complete appointment.' ,
    APPOINTMENT_RESCHEDULE_SAVED_SUCCESSFULLY:'Appointment reschedule saved successfully' ,
    APPOINTMENT_TRANSFER_SUCCESS:'Appointment transfer saved successfully' ,
    APPOINTMENT_STATUS_CHANGED_SUCCESSFULLY:'Appointment status changed successfully' ,
    DOCTOR_SCHEDULE_STATUS_CHANGED_SUCCESSFULLY:'Doctor schedule status changed successfully' ,
    APPOINTMENT_REFUND_MOUNT_SUCCESS:'Appointment Refund Amount saved success.' ,
    VITAL_SIGNS_SAVED:'Vital signs saved successfully' ,
    CREATE_DOCTOR_SCHEDULE_SUCCESS:'Doctor schedule saved successfully' ,
    UPDATE_DOCTOR_SCHEDULE_SUCCESS:'Doctor schedule updated successfully' ,
    SAVE_MEDICINE_PRESCRIPTION:'Medicine Prescription saved successfully' ,
    SAVE_SERVICE_PRESCRIPTION:'Service Prescription saved successfully' ,
    PATIENT_SAVED_SUCCESSFULLY:'Patient saved successfully' ,
    GUARDIAN_UPDATED_SUCCESSFULLY:'Guardian update successfully' ,
    GUARDIAN_SAVED_SUCCESSFULLY:'Guardian saved successfully' ,
    CHECKUP_START_SAVED_SUCCESSFULLY:'Checkup Start saved successfully' ,
    CHECKUP_COMPLETE_SAVED_SUCCESSFULLY:'Checkup complete saved successfully' ,
    PATIENT_UPDATED_SUCCESSFULLY:'Patient update successfully' ,
    DELETE_MEDICINE_PRESCRIPTION:'Medicine prescription deleted successfully' ,
    PLEASE_SELECT_MEDICINE:'Please select medicine for prescription' ,
    PLEASE_SELECT_SERVICE:'Please select service for prescription' ,
    PLEASE_SELECT_UNIT:'Please select unit' ,
    DELETE_SERVICE_PRESCRIPTION:'Service prescription deleted successfully' ,
    DOCTOR_PLEASE_SELECT:'Please select doctor' ,
    PLEASE_SELECT_APPOINTMENT:'Please select appointment' ,


    DOCTOR_SCHEDULE_CANCELED_SUCCESS:'Doctor schedule cancelled successfully' ,
    DOCTOR_SCHEDULE_CANCELED_ERROR:'Doctor schedule not cancelled ',


    DOCTOR_SCHEDULE_LEAVED_SUCCESS:'Doctor get leave for this time slot' ,
    DOCTOR_SCHEDULE_LEAVED_SUCCESS:'Time slot max appointment updated successfully' ,
    DOCTOR_SCHEDULE_LEAVED_ERROR:'Something went wrong',


    PATIENT_SCHEDULE_CANCELED_SUCCESS:'Patient schedule cancelled successfully' ,
    PATIENT_SCHEDULE_CANCELED_ERROR:'Patient schedule not cancelled ',
    THIS_DAY_NOT_HAVE_DOCTOR_APPOINTMENT:'This day not have doctor appointment ',




    // Checkups

    CHECKUP_ROOM_ALLOCATION_SAVED_SUCCESSFULLY:"Doctor checkup room has been allocated successfully.",
    CHECKUP_ROOM_ALLOCATION_SAVED_ERROR:"Doctor checkup room hasn't allocated.",

    SESSION_OUT:'Session Expired Please login...' ,
}
