export const homeConstants = {

    GET_SPECIALIZATION : 'GET_SPECIALIZATION',
    GET_SPECIALIZATION_PENDING : 'GET_SPECIALIZATION_PENDING',
    GET_SPECIALIZATION_FULFILLED : 'GET_SPECIALIZATION_FULFILLED',
    GET_SPECIALIZATION_FAILURE : 'GET_SPECIALIZATION_FAILURE',

    GET_SPECIALIZED_DOCTOR : 'GET_SPECIALIZED_DOCTOR',
    GET_SPECIALIZED_DOCTOR_PENDING : 'GET_SPECIALIZED_DOCTOR_PENDING',
    GET_SPECIALIZED_DOCTOR_FULFILLED : 'GET_SPECIALIZED_DOCTOR_FULFILLED',
    GET_SPECIALIZED_DOCTOR_FAILURE : 'GET_SPECIALIZED_DOCTOR_FAILURE',

    GET_SPECIALIZED_DOCTOR_SCHEDULE : 'GET_SPECIALIZED_DOCTOR_SCHEDULE',
    GET_SPECIALIZED_DOCTOR_SCHEDULE_PENDING : 'GET_SPECIALIZED_DOCTOR_SCHEDULE_PENDING',
    GET_SPECIALIZED_DOCTOR_SCHEDULE_FULFILLED : 'GET_SPECIALIZED_DOCTOR_SCHEDULE_FULFILLED',
    GET_SPECIALIZED_DOCTOR_SCHEDULE_FAILURE : 'GET_SPECIALIZED_DOCTOR_SCHEDULE_FAILURE',

    GET_SEARCHED_DOCTOR : 'GET_SEARCHED_DOCTOR',
    GET_SEARCHED_DOCTOR_PENDING : 'GET_SEARCHED_DOCTOR_PENDING',
    GET_SEARCHED_DOCTOR_FULFILLED : 'GET_SEARCHED_DOCTOR_FULFILLED',
    GET_SEARCHED_DOCTOR_FAILURE : 'GET_SEARCHED_DOCTOR_FAILURE',

    GET_SPECIALIZED_SCHEDULE : 'GET_SPECIALIZED_SCHEDULE',
    GET_SPECIALIZED_SCHEDULE_PENDING : 'GET_SPECIALIZED_SCHEDULE_PENDING',
    GET_SPECIALIZED_SCHEDULE_FULFILLED : 'GET_SPECIALIZED_SCHEDULE_FULFILLED',
    GET_SPECIALIZED_SCHEDULE_FAILURE : 'GET_SPECIALIZED_SCHEDULE_FAILURE',

    GET_SPECIALIZED_DOCTOR_APPOINTMENTS : 'GET_SPECIALIZED_DOCTOR_APPOINTMENTS',
    GET_SPECIALIZED_DOCTOR_APPOINTMENTS_PENDING : 'GET_SPECIALIZED_DOCTOR_APPOINTMENTS_PENDING',
    GET_SPECIALIZED_DOCTOR_APPOINTMENTS_FULFILLED : 'GET_SPECIALIZED_DOCTOR_APPOINTMENTS_FULFILLED',
    GET_SPECIALIZED_DOCTOR_APPOINTMENTS_FAILURE : 'GET_SPECIALIZED_DOCTOR_APPOINTMENTS_FAILURE',

    GET_APPOINTMENTS : 'GET_APPOINTMENTS',
    GET_APPOINTMENTS_PENDING : 'GET_APPOINTMENTS_PENDING',
    GET_APPOINTMENTS_FULFILLED : 'GET_APPOINTMENTS_FULFILLED',
    GET_APPOINTMENTS_FAILURE : 'GET_APPOINTMENTS_FAILURE',


    GET_DOCTORS_AVAILABLITIES : 'GET_DOCTORS_AVAILABLITIES',
    GET_DOCTORS_AVAILABLITIES_PENDING : 'GET_DOCTORS_AVAILABLITIES_PENDING',
    GET_DOCTORS_AVAILABLITIES_FULFILLED : 'GET_DOCTORS_AVAILABLITIES_FULFILLED',
    GET_DOCTORS_AVAILABLITIES_FAILURE : 'GET_DOCTORS_AVAILABLITIES_FAILURE',

    GET_DOCOTORS_APPOINTMENTS_FOR_TIMESLOT : 'GET_DOCOTORS_APPOINTMENTS_FOR_TIMESLOT',
    GET_DOCOTORS_APPOINTMENTS_FOR_TIMESLOT_PENDING : 'GET_DOCOTORS_APPOINTMENTS_FOR_TIMESLOT_PENDING',
    GET_DOCOTORS_APPOINTMENTS_FOR_TIMESLOT_FULFILLED : 'GET_DOCOTORS_APPOINTMENTS_FOR_TIMESLOT_FULFILLED',
    GET_DOCOTORS_APPOINTMENTS_FOR_TIMESLOT_FAILURE : 'GET_DOCOTORS_APPOINTMENTS_FOR_TIMESLOT_FAILURE',

    GET_DOCOTORS_CALENDAR : 'GET_DOCOTORS_CALENDAR',
    GET_DOCOTORS_CALENDAR_PENDING : 'GET_DOCOTORS_CALENDAR_PENDING',
    GET_DOCOTORS_CALENDAR_FULFILLED : 'GET_DOCOTORS_CALENDAR_FULFILLED',
    GET_DOCOTORS_CALENDAR_FAILURE : 'GET_DOCOTORS_CALENDAR_FAILURE',
    
    CONFIRMED_DOCTOR_TIMESLOT_BY_AVALAIBLITY : 'CONFIRMED_DOCTOR_TIMESLOT_BY_AVALAIBLITY',
    CONFIRMED_DOCTOR_TIMESLOT_BY_AVALAIBLITY_PENDING : 'CONFIRMED_DOCTOR_TIMESLOT_BY_AVALAIBLITY_PENDING',
    CONFIRMED_DOCTOR_TIMESLOT_BY_AVALAIBLITY_FULFILLED : 'CONFIRMED_DOCTOR_TIMESLOT_BY_AVALAIBLITY_FULFILLED',
    CONFIRMED_DOCTOR_TIMESLOT_BY_AVALAIBLITY_FAILURE : 'CONFIRMED_DOCTOR_TIMESLOT_BY_AVALAIBLITY_FAILURE',
    
    GET_DOCTOR_TIME_SLOT_APPOINTMENTS : 'GET_DOCTOR_TIME_SLOT_APPOINTMENTS',
    GET_DOCTOR_TIME_SLOT_APPOINTMENTS_PENDING : 'GET_DOCTOR_TIME_SLOT_APPOINTMENTS_PENDING',
    GET_DOCTOR_TIME_SLOT_APPOINTMENTS_FULFILLED : 'GET_DOCTOR_TIME_SLOT_APPOINTMENTS_FULFILLED',
    GET_DOCTOR_TIME_SLOT_APPOINTMENTS_FAILURE : 'GET_DOCTOR_TIME_SLOT_APPOINTMENTS_FAILURE',
    GET_DOCTOR_TIME_SLOT_APPOINTMENTS_REJECTED : 'GET_DOCTOR_TIME_SLOT_APPOINTMENTS_REJECTED',
    
    GET_DOCTOR_TIME_SLOTS : 'GET_DOCTOR_TIME_SLOTS',
    GET_DOCTOR_TIME_SLOTS_PENDING : 'GET_DOCTOR_TIME_SLOTS_PENDING',
    GET_DOCTOR_TIME_SLOTS_FULFILLED : 'GET_DOCTOR_TIME_SLOTS_FULFILLED',
    GET_DOCTOR_TIME_SLOTS_FAILURE : 'GET_DOCTOR_TIME_SLOTS_FAILURE',
    GET_DOCTOR_TIME_SLOTS_REJECTED : 'GET_DOCTOR_TIME_SLOTS_REJECTED',
    
    GET_SEARCH_HOME_APPOINTMENTS : 'GET_SEARCH_HOME_APPOINTMENTS',
    GET_SEARCH_HOME_APPOINTMENTS_PENDING : 'GET_SEARCH_HOME_APPOINTMENTS_PENDING',
    GET_SEARCH_HOME_APPOINTMENTS_FULFILLED : 'GET_SEARCH_HOME_APPOINTMENTS_FULFILLED',
    GET_SEARCH_HOME_APPOINTMENTS_FAILURE : 'GET_SEARCH_HOME_APPOINTMENTS_FAILURE',
    GET_SEARCH_HOME_APPOINTMENTS_REJECTED : 'GET_SEARCH_HOME_APPOINTMENTS_REJECTED',

};
