import { messagesEnum } from "../components/Enums/messagesEnum";
import { backSystemSetupURL, backURL } from "./apptimedRequest";
import toastr from "toastr";

export function exceptionHandling(rejectedResponse) {
    
    if(rejectedResponse === undefined || rejectedResponse === null ){
        return "Unable process data";
    }
    
    if(rejectedResponse.data === undefined || rejectedResponse.data === null ){
        return "Unable process data";
    }

    if(rejectedResponse.status === undefined || rejectedResponse.status === null ){
        return "Unable process data";
    }

    if(rejectedResponse.status === 417  ){
        return {msg:rejectedResponse.data.msg  , error:rejectedResponse?.data?.error};
    }

    if(rejectedResponse.status === 400  ){
        return {msg:rejectedResponse.data.msg , error:rejectedResponse?.data?.error};
    }

    if(rejectedResponse.status === 404  ){
        return {msg:rejectedResponse.data.msg  , error:rejectedResponse?.data?.error};
    }

    if(rejectedResponse.status === 401  ){
        toastr.error(messagesEnum.ERROR , messagesEnum.SESSION_OUT)
        // window.location.assign(backSystemSetupURL)
        // localStorage.clear();
        window.location.assign(`${backURL}login`)
        return {msg:rejectedResponse.data.msg  , error:rejectedResponse?.data?.error};
    }
    if(rejectedResponse.status === 403  ){
        return {msg:'Access Denied'  , error:rejectedResponse?.data?.error};
    }
    if(rejectedResponse.status === 500  ){
        return {msg:'Some thing went wrong' , error:rejectedResponse?.data?.error};
    }


}
