import axios from 'axios';
import {exceptionHandling} from './exceprtionHandling.js';

// const baseURL = 'https://cors-anywhere.herokuapp.com/http://139.59.28.165:8000/api/';
// const baseURL = 'https://192.168.8.100:9000/api/v1/';


export const baseURL = 'https://dms-nchbackend.apptimed.com/api/v1/';// testing
export const backURL = 'https://dms-systemsetup.apptimed.com/' // testing
export const backCashierURL = 'https://dms-cashier.apptimed.com/' // testing
export const backSystemSetupURL = 'https://dms-systemsetup.apptimed.com/';// testing
export const REPORT_URL = 'https://dms-reports.apptimed.com';

// export const baseURL = 'https://192.168.1.27:1080/api/v1/';// NCH Local
// export const backURL = 'http://nchsystemsetup.com/' // NCH Local


// export const baseURL = 'https://nchbackend.apptimed.com/api/v1/'; // live 
// export const backURL = 'https://systemsetup.apptimed.com/'  // live 

export const OPD_URL="http://dms-opd.apptimed.com/login"
export const INVENTORY_URL="http://dms-pharmacy.apptimed.com/login"
export const CASHIER_URL="http://dms-cashier.apptimed.com/login"
export const ACCOUNT_URL="http://dms-cashier.apptimed.com/login"
export const HR_URL="http://dms-hr.apptimed.com/login"
export const IPD_URL="http://dms-theater.apptimed.com/login"

let getAccessToken = function () {
    try {        
        return "Bearer "+localStorage.getItem('token');
        // return "Bearer "+JSON.parse(localStorage.getItem('token'));
    } catch (e) {
        return "";
    }
}

const setURL = (uri,isSystemSetup = false) =>{
    let dId =  'did='+localStorage?.did
    let tId =  `tid=${localStorage?.tid ?? '7bbc5fa5-f6c2-4ae2-8abf-9fc36a547b81'}`
    let _url = "";

    _url = isSystemSetup ? backSystemSetupURL+uri : baseURL+uri
    
    _url += (_url.split('?')[1] ? '&':'?') + dId+'&'+tId;
    return _url;
}

const prepareConfig = {
    getheaders: {'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':  "Origin, X-Requested-With, Content-Type, Accept, Authorization",  
    'Authorization':getAccessToken(),
    'Content-Type': 'application/json; charset=utf-8',
      
       },
    postheaders: {  'Access-Control-Allow-Origin': '*',
       'Access-Control-Allow-Headers':  "Origin, X-Requested-With, Content-Type, Accept, Authorization",  
       'Authorization':getAccessToken(),
       'Content-Type': 'application/json; charset=utf-8',
   }
//     postheaders: {  'Access-Control-Allow-Origin': '*',
//        'Access-Control-Allow-Headers':  "Origin, X-Requested-With, Content-Type, Accept, Authorization",  
//        'Authorization':getAccessToken(),
//        'Content-Type': 'multipart/form-data'
//    }

};

let apptimedRequest = {
    get:async function (uri,isSystemSetup = false,source,newSource) {
        // let cancelToken
        // if (typeof cancelToken != typeof undefined) {
        //     cancelToken.cancel("Operation canceled due to new request.")
        //   }
        
        //   //Save the cancel token for the current request
        //   cancelToken = axios.CancelToken.source()

        console.log("source",source);
        if (source) {
            source.cancel('Request canceled by the user');
        }

        return  new Promise((resolve,reject)=>{
        axios({
            method: 'get',
            url: setURL(uri,isSystemSetup),
            headers: prepareConfig.getheaders,
            cancelToken: newSource?.token
        })
        .then( response =>{
            
           resolve(response);
           console.log("🚀 ~ file: apptimedRequest.js ~ line 57 ~ returnnewPromise ~ response", response)
        })
        .catch(error=> {
        console.log("🚀 ~ file: apptimedRequest.js ~ line 60 ~ get returnnewPromise ~ error", error)
          
           
            reject( exceptionHandling(error.response));
            // dispatch(failureFunction(error))
        })
    })
       
    }, 
  
    post: async function (uri,body) {
        return new Promise((resolve,reject)=>{
            axios({
                method: 'post',
                url: setURL(uri) ,
                data:body,
                headers: prepareConfig.postheaders,
            })
            .then( response =>{
                
               resolve(response);
            })
            .catch(error=> {
                reject( exceptionHandling(error.response));
                // dispatch(failureFunction(error))
            })
        })
    },
    patch: async function (uri,body) {
        return new Promise((resolve,reject)=>{
            axios({
                method: 'patch',
                url: setURL(uri),
                params:body,
                headers: prepareConfig.postheaders,
            })
            .then( response =>{
                
                resolve(response);
             })
             .catch(error=> {
                
                reject( exceptionHandling(error.response));
                 // dispatch(failureFunction(error))
             })
         })
    },
    put: async function (uri,body) {
        return new Promise((resolve,reject)=>{
            axios({
                method: 'put',
                url: setURL(uri) ,
                data:body,
                headers: prepareConfig.postheaders,
            })
            .then( response =>{
                
                resolve(response);
             })
             .catch(error=> {
                
                reject( exceptionHandling(error.response));
                 // dispatch(failureFunction(error))
             })
         })
    },
    delete: async function (uri,body) {
        return new Promise((resolve,reject)=>{
            axios({
                method: 'delete',
                url: setURL(uri) ,
                params:body,
                headers: prepareConfig.postheaders,
            })
            .then( response =>{
                
                resolve(response);
             })
             .catch(error=> {
                
                reject( exceptionHandling(error.response));
             })
         })
    }
};

export default apptimedRequest;
