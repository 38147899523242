import apptimedRequest from '../../../connections/apptimedRequest.js';

var loginLoaderSource = {

    login: function (token){

        return apptimedRequest.post('login'+token,null);
    },

    getPermissions: function (){
        
       
       
            return  new Promise((resolve,reject)=>{
             if( localStorage.getItem('permissions') != null ){
                resolve( JSON.parse(localStorage.getItem('permissions')));

             }else{
                resolve( []);

             }

            
         })
            
        
    }
  
  
};

export default loginLoaderSource;