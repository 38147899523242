export const appointmentConstants = {


    GET_ALL_APPOINTMENTS : 'GET_ALL_APPOINTMENTS',
    GET_ALL_APPOINTMENTS_PENDING : 'GET_ALL_APPOINTMENTS_PENDING',
    GET_ALL_APPOINTMENTS_FULFILLED : 'GET_ALL_APPOINTMENTS_FULFILLED',
    GET_ALL_APPOINTMENTS_FAILURE : 'GET_ALL_APPOINTMENTS_FAILURE',


    GET_ONE_APPOINTMENT_DETAILS : 'GET_ONE_APPOINTMENT_DETAILS',
    GET_ONE_APPOINTMENT_DETAILS_PENDING : 'GET_ONE_APPOINTMENT_DETAILS_PENDING',
    GET_ONE_APPOINTMENT_DETAILS_FULFILLED : 'GET_ONE_APPOINTMENT_DETAILS_FULFILLED',
    GET_ONE_APPOINTMENT_DETAILS_FAILURE : 'GET_ONE_APPOINTMENT_DETAILS_FAILURE',
    GET_ONE_APPOINTMENT_DETAILS_REJECTED : 'GET_ONE_APPOINTMENT_DETAILS_REJECTED',

    GET_MOBILE_NO : 'GET_MOBILE_NO',
    GET_MOBILE_NO_PENDING : 'GET_MOBILE_NO_PENDING',
    GET_MOBILE_NO_FULFILLED : 'GET_MOBILE_NO_FULFILLED',
    GET_MOBILE_NO_FAILURE : 'GET_MOBILE_NO_FAILURE',



    GET_GUARDIANS_FOR_PARTNER : 'GET_GUARDIANS_FOR_PARTNER',
    GET_GUARDIANS_FOR_PARTNER_PENDING : 'GET_GUARDIANS_FOR_PARTNER_PENDING',
    GET_GUARDIANS_FOR_PARTNER_FULFILLED : 'GET_GUARDIANS_FOR_PARTNER_FULFILLED',
    GET_GUARDIANS_FOR_PARTNER_FAILURE : 'GET_PATIENT_TYPE_FAILURE',
    GET_GUARDIANS_FOR_PARTNER_REJECTED : 'GET_PATIENT_TYPE_REJECTED',


    GET_DOCTORS : 'GET_DOCTORS',
    GET_DOCTORS_PENDING : 'GET_DOCTORS_PENDING',
    GET_DOCTORS_FULFILLED : 'GET_DOCTORS_FULFILLED',
    GET_DOCTORS_FAILURE : 'GET_DOCTORS_FAILURE',
    GET_DOCTORS_REJECTED : 'GET_DOCTORS_REJECTED',

    GET_DOCTOR_FEES : 'GET_DOCTOR_FEES',
    GET_DOCTOR_FEES_PENDING : 'GET_DOCTOR_FEES_PENDING',
    GET_DOCTOR_FEES_FULFILLED : 'GET_DOCTOR_FEES_FULFILLED',
    GET_DOCTOR_FEES_FAILURE : 'GET_DOCTOR_FEES_FAILURE',
    GET_DOCTOR_FEES_REJECTED : 'GET_DOCTOR_FEES_REJECTED',

    SAVE_DOCTOR_APPOINTMENT_REFUND : 'SAVE_DOCTOR_APPOINTMENT_REFUND',
    SAVE_DOCTOR_APPOINTMENT_REFUND_PENDING : 'SAVE_DOCTOR_APPOINTMENT_REFUND_PENDING',
    SAVE_DOCTOR_APPOINTMENT_REFUND_FULFILLED : 'SAVE_DOCTOR_APPOINTMENT_REFUND_FULFILLED',
    SAVE_DOCTOR_APPOINTMENT_REFUND_FAILURE : 'SAVE_DOCTOR_APPOINTMENT_REFUND_FAILURE',
    SAVE_DOCTOR_APPOINTMENT_REFUND_REJECTED : 'SAVE_DOCTOR_APPOINTMENT_REFUND_REJECTED',


    GET_DOCTOR_TIME_SLOTS : 'GET_DOCTOR_TIME_SLOTS',
    GET_DOCTOR_TIME_SLOTS_PENDING : 'GET_DOCTOR_TIME_SLOTS_PENDING',
    GET_DOCTOR_TIME_SLOTS_FULFILLED : 'GET_DOCTOR_TIME_SLOTS_FULFILLED',
    GET_DOCTOR_TIME_SLOTS_FAILURE : 'GET_DOCTOR_TIME_SLOTS_FAILURE',
    GET_DOCTOR_TIME_SLOTS_REJECTED : 'GET_DOCTOR_TIME_SLOTS_REJECTED',

    SAVE_DOCTOR_APPOINTMENT_TRANSFER : 'SAVE_DOCTOR_APPOINTMENT_TRANSFER',
    SAVE_DOCTOR_APPOINTMENT_TRANSFER_PENDING : 'SAVE_DOCTOR_APPOINTMENT_TRANSFER_PENDING',
    SAVE_DOCTOR_APPOINTMENT_TRANSFER_FULFILLED : 'SAVE_DOCTOR_APPOINTMENT_TRANSFER_FULFILLED',
    SAVE_DOCTOR_APPOINTMENT_TRANSFER_FAILURE : 'SAVE_DOCTOR_APPOINTMENT_TRANSFER_FAILURE',
    SAVE_DOCTOR_APPOINTMENT_TRANSFER_REJECTED : 'SAVE_DOCTOR_APPOINTMENT_TRANSFER_REJECTED',

    GET_DOCTOR_TIME_SLOT_APPOINTMENTS : 'GET_DOCTOR_TIME_SLOT_APPOINTMENTS',
    GET_DOCTOR_TIME_SLOT_APPOINTMENTS_PENDING : 'GET_DOCTOR_TIME_SLOT_APPOINTMENTS_PENDING',
    GET_DOCTOR_TIME_SLOT_APPOINTMENTS_FULFILLED : 'GET_DOCTOR_TIME_SLOT_APPOINTMENTS_FULFILLED',
    GET_DOCTOR_TIME_SLOT_APPOINTMENTS_FAILURE : 'GET_DOCTOR_TIME_SLOT_APPOINTMENTS_FAILURE',
    GET_DOCTOR_TIME_SLOT_APPOINTMENTS_REJECTED : 'GET_DOCTOR_TIME_SLOT_APPOINTMENTS_REJECTED',

    GET_DOCTOR_TIME_SLOT_RESCHEDULE_APPOINTMENTS : 'GET_DOCTOR_TIME_SLOT_RESCHEDULE_APPOINTMENTS',
    GET_DOCTOR_TIME_SLOT_RESCHEDULE_APPOINTMENTS_PENDING : 'GET_DOCTOR_TIME_SLOT_RESCHEDULE_APPOINTMENTS_PENDING',
    GET_DOCTOR_TIME_SLOT_RESCHEDULE_APPOINTMENTS_FULFILLED : 'GET_DOCTOR_TIME_SLOT_RESCHEDULE_APPOINTMENTS_FULFILLED',
    GET_DOCTOR_TIME_SLOT_RESCHEDULE_APPOINTMENTS_FAILURE : 'GET_DOCTOR_TIME_SLOT_RESCHEDULE_APPOINTMENTS_FAILURE',
    GET_DOCTOR_TIME_SLOT_RESCHEDULE_APPOINTMENTS_REJECTED : 'GET_DOCTOR_TIME_SLOT_RESCHEDULE_APPOINTMENTS_REJECTED',


    GET_ADD_APPOINTMENT_PATIENTS : 'GET_ADD_APPOINTMENT_PATIENTS',
    GET_ADD_APPOINTMENT_PATIENTS_PENDING : 'GET_ADD_APPOINTMENT_PATIENTS_PENDING',
    GET_ADD_APPOINTMENT_PATIENTS_FULFILLED : 'GET_ADD_APPOINTMENT_PATIENTS_FULFILLED',
    GET_ADD_APPOINTMENT_PATIENTS_FAILURE : 'GET_ADD_APPOINTMENT_PATIENTS_FAILURE',
    GET_ADD_APPOINTMENT_PATIENTS_REJECTED : 'GET_ADD_APPOINTMENT_PATIENTS_REJECTED',


    GET_ADD_APPOINTMENT_GUARDIANS : 'GET_ADD_APPOINTMENT_GUARDIANS',
    GET_ADD_APPOINTMENT_GUARDIANS_PENDING : 'GET_ADD_APPOINTMENT_GUARDIANS_PENDING',
    GET_ADD_APPOINTMENT_GUARDIANS_FULFILLED : 'GET_ADD_APPOINTMENT_GUARDIANS_FULFILLED',
    GET_ADD_APPOINTMENT_GUARDIANS_FAILURE : 'GET_ADD_APPOINTMENT_GUARDIANS_FAILURE',
    GET_ADD_APPOINTMENT_GUARDIANS_REJECTED : 'GET_ADD_APPOINTMENT_GUARDIANS_REJECTED',


    GET_REFERRED_DOCTORS : 'GET_REFERRED_DOCTORS',
    GET_REFERRED_DOCTORS_PENDING : 'GET_REFERRED_DOCTORS_PENDING',
    GET_REFERRED_DOCTORS_FULFILLED : 'GET_REFERRED_DOCTORS_FULFILLED',
    GET_REFERRED_DOCTORS_FAILURE : 'GET_REFERRED_DOCTORS_FAILURE',
    GET_REFERRED_DOCTORS_REJECTED : 'GET_REFERRED_DOCTORS_REJECTED',

    GET_REFERRED_CENTERS : 'GET_REFERRED_CENTERS',
    GET_REFERRED_CENTERS_PENDING : 'GET_REFERRED_CENTERS_PENDING',
    GET_REFERRED_CENTERS_FULFILLED : 'GET_REFERRED_CENTERS_FULFILLED',
    GET_REFERRED_CENTERS_FAILURE : 'GET_REFERRED_CENTERS_FAILURE',
    GET_REFERRED_CENTERS_REJECTED : 'GET_REFERRED_CENTERS_REJECTED',



    GET_PARTNERS : 'GET_PARTNERS',
    GET_PARTNERS_PENDING : 'GET_PARTNERS_PENDING',
    GET_PARTNERS_FULFILLED : 'GET_PARTNERS_FULFILLED',
    GET_PARTNERS_FAILURE : 'GET_PARTNERS_FAILURE',
    GET_PARTNERS_REJECTED : 'GET_PARTNERS_REJECTED',


    SAVE_APPOINTMENT : 'SAVE_APPOINTMENT',
    SAVE_APPOINTMENT_PENDING : 'SAVE_APPOINTMENT_PENDING',
    SAVE_APPOINTMENT_FULFILLED : 'SAVE_APPOINTMENT_FULFILLED',
    SAVE_APPOINTMENT_FAILURE : 'SAVE_APPOINTMENT_FAILURE',
    SAVE_APPOINTMENT_REJECTED : 'SAVE_APPOINTMENT_REJECTED',

    UPDATE_APPOINTMENT : 'UPDATE_APPOINTMENT',
    UPDATE_APPOINTMENT_PENDING : 'UPDATE_APPOINTMENT_PENDING',
    UPDATE_APPOINTMENT_FULFILLED : 'UPDATE_APPOINTMENT_FULFILLED',
    UPDATE_APPOINTMENT_FAILURE : 'UPDATE_APPOINTMENT_FAILURE',
    UPDATE_APPOINTMENT_REJECTED : 'UPDATE_APPOINTMENT_REJECTED',


    UPDATE_STATUS_APPOINTMENT : 'UPDATE_STATUS_APPOINTMENT',
    UPDATE_STATUS_APPOINTMENT_PENDING : 'UPDATE_STATUS_APPOINTMENT_PENDING',
    UPDATE_STATUS_APPOINTMENT_FULFILLED : 'UPDATE_STATUS_APPOINTMENT_FULFILLED',
    UPDATE_STATUS_APPOINTMENT_FAILURE : 'UPDATE_STATUS_APPOINTMENT_FAILURE',
    UPDATE_STATUS_APPOINTMENT_REJECTED : 'UPDATE_STATUS_APPOINTMENT_REJECTED',


    PATIENT_RESCHEDULE_APPOINTMENT : 'PATIENT_RESCHEDULE_APPOINTMENT',
    PATIENT_RESCHEDULE_APPOINTMENT_PENDING : 'PATIENT_RESCHEDULE_APPOINTMENT_PENDING',
    PATIENT_RESCHEDULE_APPOINTMENT_FULFILLED : 'PATIENT_RESCHEDULE_APPOINTMENT_FULFILLED',
    PATIENT_RESCHEDULE_APPOINTMENT_FAILURE : 'PATIENT_RESCHEDULE_APPOINTMENT_FAILURE',
    PATIENT_RESCHEDULE_APPOINTMENT_REJECTED : 'PATIENT_RESCHEDULE_APPOINTMENT_REJECTED',

    PATIENT_SAVE_RESCHEDULE_APPOINTMENT : 'PATIENT_SAVE_RESCHEDULE_APPOINTMENT',
    PATIENT_SAVE_RESCHEDULE_APPOINTMENT_PENDING : 'PATIENT_SAVE_RESCHEDULE_APPOINTMENT_PENDING',
    PATIENT_SAVE_RESCHEDULE_APPOINTMENT_FULFILLED : 'PATIENT_SAVE_RESCHEDULE_APPOINTMENT_FULFILLED',
    PATIENT_SAVE_RESCHEDULE_APPOINTMENT_FAILURE : 'PATIENT_SAVE_RESCHEDULE_APPOINTMENT_FAILURE',
    PATIENT_SAVE_RESCHEDULE_APPOINTMENT_REJECTED : 'PATIENT_SAVE_RESCHEDULE_APPOINTMENT_REJECTED',



    CANCEL_PATIENT_APPOINTMENT : 'CANCEL_PATIENT_APPOINTMENT',
    CANCEL_PATIENT_APPOINTMENT_PENDING : 'CANCEL_PATIENT_APPOINTMENT_PENDING',
    CANCEL_PATIENT_APPOINTMENT_FULFILLED : 'CANCEL_PATIENT_APPOINTMENT_FULFILLED',
    CANCEL_PATIENT_APPOINTMENT_FAILURE : 'CANCEL_PATIENT_APPOINTMENT_FAILURE',
    CANCEL_PATIENT_APPOINTMENT_REJECTED : 'CANCEL_PATIENT_APPOINTMENT_REJECTED',

    CANCEL_DOCTOR_APPOINTMENT : 'CANCEL_DOCTOR_APPOINTMENT',
    CANCEL_DOCTOR_APPOINTMENT_PENDING : 'CANCEL_DOCTOR_APPOINTMENT_PENDING',
    CANCEL_DOCTOR_APPOINTMENT_FULFILLED : 'CANCEL_DOCTOR_APPOINTMENT_FULFILLED',
    CANCEL_DOCTOR_APPOINTMENT_FAILURE : 'CANCEL_DOCTOR_APPOINTMENT_FAILURE',
    CANCEL_DOCTOR_APPOINTMENT_REJECTED : 'CANCEL_DOCTOR_APPOINTMENT_REJECTED',


    LEAVE_DOCTOR_TIME_SLOT : 'LEAVE_DOCTOR_TIME_SLOT',
    LEAVE_DOCTOR_TIME_SLOT_PENDING : 'LEAVE_DOCTOR_TIME_SLOT_PENDING',
    LEAVE_DOCTOR_TIME_SLOT_FULFILLED : 'LEAVE_DOCTOR_TIME_SLOT_FULFILLED',
    LEAVE_DOCTOR_TIME_SLOT_FAILURE : 'LEAVE_DOCTOR_TIME_SLOT_FAILURE',
    LEAVE_DOCTOR_TIME_SLOT_REJECTED : 'LEAVE_DOCTOR_TIME_SLOT_REJECTED',

    DOCTOR_TIME_SLOT_COUNT : 'DOCTOR_TIME_SLOT_COUNT',
    DOCTOR_TIME_SLOT_COUNT_PENDING : 'DOCTOR_TIME_SLOT_COUNT_PENDING',
    DOCTOR_TIME_SLOT_COUNT_FULFILLED : 'DOCTOR_TIME_SLOT_COUNT_FULFILLED',
    DOCTOR_TIME_SLOT_COUNT_FAILURE : 'DOCTOR_TIME_SLOT_COUNT_FAILURE',
    DOCTOR_TIME_SLOT_COUNT_REJECTED : 'DOCTOR_TIME_SLOT_COUNT_REJECTED',


    DELETE_PATIENT_APPOINTMENT : 'DELETE_PATIENT_APPOINTMENT',
    DELETE_PATIENT_APPOINTMENT_PENDING : 'DELETE_PATIENT_APPOINTMENT_PENDING',
    DELETE_PATIENT_APPOINTMENT_FULFILLED : 'DELETE_PATIENT_APPOINTMENT_FULFILLED',
    DELETE_PATIENT_APPOINTMENT_FAILURE : 'DELETE_PATIENT_APPOINTMENT_FAILURE',
    DELETE_PATIENT_APPOINTMENT_REJECTED : 'DELETE_PATIENT_APPOINTMENT_REJECTED',


    GET_CHECKUP_FOR_APPOINTMENT : 'GET_CHECKUP_FOR_APPOINTMENT',
    GET_CHECKUP_FOR_APPOINTMENT_PENDING : 'GET_CHECKUP_FOR_APPOINTMENT_PENDING',
    GET_CHECKUP_FOR_APPOINTMENT_FULFILLED : 'GET_CHECKUP_FOR_APPOINTMENT_FULFILLED',
    GET_CHECKUP_FOR_APPOINTMENT_FAILURE : 'GET_CHECKUP_FOR_APPOINTMENT_FAILURE',
    GET_CHECKUP_FOR_APPOINTMENT_REJECTED : 'GET_CHECKUP_FOR_APPOINTMENT_REJECTED',

    GET_APPOINTMENT_PRINT : 'GET_APPOINTMENT_PRINT',
    GET_APPOINTMENT_PRINT_PENDING : 'GET_APPOINTMENT_PRINT_PENDING',
    GET_APPOINTMENT_PRINT_FULFILLED : 'GET_APPOINTMENT_PRINT_FULFILLED',
    GET_APPOINTMENT_PRINT_FAILURE : 'GET_APPOINTMENT_PRINT_FAILURE',
    GET_APPOINTMENT_PRINT_REJECTED : 'GET_APPOINTMENT_PRINT_REJECTED',

    GET_PATIENT_APPOINTMENT_CREATE : 'GET_PATIENT_APPOINTMENT_CREATE',
    GET_PATIENT_APPOINTMENT_CREATE_PENDING : 'GET_PATIENT_APPOINTMENT_CREATE_PENDING',
    GET_PATIENT_APPOINTMENT_CREATE_FULFILLED : 'GET_PATIENT_APPOINTMENT_CREATE_FULFILLED',
    GET_PATIENT_APPOINTMENT_CREATE_FAILURE : 'GET_PATIENT_APPOINTMENT_CREATE_FAILURE',
    GET_PATIENT_APPOINTMENT_CREATE_REJECTED : 'GET_PATIENT_APPOINTMENT_CREATE_REJECTED',

    UPDATE_DOCTOR_TIME_SLOT_STATUS : 'UPDATE_DOCTOR_TIME_SLOT_STATUS',
    UPDATE_DOCTOR_TIME_SLOT_STATUS_PENDING : 'UPDATE_DOCTOR_TIME_SLOT_STATUS_PENDING',
    UPDATE_DOCTOR_TIME_SLOT_STATUS_FULFILLED : 'UPDATE_DOCTOR_TIME_SLOT_STATUS_FULFILLED',
    UPDATE_DOCTOR_TIME_SLOT_STATUS_FAILURE : 'UPDATE_DOCTOR_TIME_SLOT_STATUS_FAILURE',
    UPDATE_DOCTOR_TIME_SLOT_STATUS_REJECTED : 'UPDATE_DOCTOR_TIME_SLOT_STATUS_REJECTED',


    APPOINTMENT_SAVED_STATUS_CHANGE : 'APPOINTMENT_SAVED_STATUS_CHANGE',
    APPOINTMENT_PATIENT_SELECT_REMOVE : 'APPOINTMENT_PATIENT_SELECT_REMOVE',
    APPOINTMENT_PATIENT_CLEAR : 'APPOINTMENT_PATIENT_CLEAR',


};
