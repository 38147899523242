export const doctorScheduleConstants = {

    FETCH_ALL_AVAILABLITIES : 'FETCH_ALL_AVAILABLITIES',
    FETCH_ALL_AVAILABLITIES_PENDING : 'FETCH_ALL_AVAILABLITIES_PENDING',
    FETCH_ALL_AVAILABLITIES_FULFILLED : 'FETCH_ALL_AVAILABLITIES_FULFILLED',
    FETCH_ALL_AVAILABLITIES_FAILURE : 'FETCH_ALL_AVAILABLITIES_FAILURE',

    CREATE_DOCTOR_AVAILABILITY : 'CREATE_DOCTOR_AVAILABILITY',
    CREATE_DOCTOR_AVAILABILITY_PENDING : 'CREATE_DOCTOR_AVAILABILITY_PENDING',
    CREATE_DOCTOR_AVAILABILITY_FULFILLED : 'CREATE_DOCTOR_AVAILABILITY_FULFILLED',
    CREATE_DOCTOR_AVAILABILITY_FAILURE : 'CREATE_DOCTOR_AVAILABILITY_FAILURE',


    FETCH_DOCTOR_AVAILABLITY : 'FETCH_DOCTOR_AVAILABLITY',
    FETCH_DOCTOR_AVAILABLITY_PENDING : 'FETCH_DOCTOR_AVAILABLITY_PENDING',
    FETCH_DOCTOR_AVAILABLITY_FULFILLED : 'FETCH_DOCTOR_AVAILABLITY_FULFILLED',
    FETCH_DOCTOR_AVAILABLITY_FAILURE : 'FETCH_DOCTOR_AVAILABLITY_FAILURE',


    CHANGE_STATUS_OF_DOCTOR_AVAILABLITY : 'CHANGE_STATUS_OF_DOCTOR_AVAILABLITY',
    CHANGE_STATUS_OF_DOCTOR_AVAILABLITY_PENDING : 'CHANGE_STATUS_OF_DOCTOR_AVAILABLITY_PENDING',
    CHANGE_STATUS_OF_DOCTOR_AVAILABLITY_FULFILLED : 'CHANGE_STATUS_OF_DOCTOR_AVAILABLITY_FULFILLED',
    CHANGE_STATUS_OF_DOCTOR_AVAILABLITY_FAILURE : 'CHANGE_STATUS_OF_DOCTOR_AVAILABLITY_FAILURE',
    CHANGE_STATUS_OF_DOCTOR_AVAILABLITY_REJECTED : 'CHANGE_STATUS_OF_DOCTOR_AVAILABLITY_REJECTED',

    UPDATE_DOCTOR_AVAILABLITY : 'UPDATE_DOCTOR_AVAILABLITY',
    UPDATE_DOCTOR_AVAILABLITY_PENDING : 'UPDATE_DOCTOR_AVAILABLITY_PENDING',
    UPDATE_DOCTOR_AVAILABLITY_FULFILLED : 'UPDATE_DOCTOR_AVAILABLITY_FULFILLED',
    UPDATE_DOCTOR_AVAILABLITY_FAILURE : 'UPDATE_DOCTOR_AVAILABLITY_FAILURE',


    CLEAR_DOCTOR_AVAILABLITY : 'CLEAR_DOCTOR_AVAILABLITY',
    CLEAR_DOCTOR_AVAILABLITY_PENDING : 'CLEAR_DOCTOR_AVAILABLITY_PENDING',
    CLEAR_DOCTOR_AVAILABLITY_FULFILLED : 'CLEAR_DOCTOR_AVAILABLITY_FULFILLED',
    CLEAR_DOCTOR_AVAILABLITY_FAILURE : 'CLEAR_DOCTOR_AVAILABLITY_FAILURE',
    
    DOCTOR_CLEAR_SAVE : 'DOCTOR_CLEAR_SAVE',

};
