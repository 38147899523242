import { doctorScheduleConstants } from "./constants"

export default function doctorScheduleReducer(state={
    loading:false,
    fetched:false,
    updateLoading:false,
    updateFetched:false,
    createLoading:false,
    createFetched:false,
    error: null,
    doctorAvailability: {},
    doctorAvailabilities: [],

  }, action) {

    switch (action.type) {

        case doctorScheduleConstants.FETCH_ALL_AVAILABLITIES_PENDING : {
            return {...state,loading:true, fetched: false, user: action.payload}
        }  
        case  doctorScheduleConstants.FETCH_ALL_AVAILABLITIES_FULFILLED: {
            return {...state,loading: false,fetched: true, doctorAvailabilities:action?.payload?.data?.data}
        }    
        case doctorScheduleConstants.FETCH_ALL_AVAILABLITIES_FAILURE :  {
            return {...state, loading: false, fetched: false, error:action.error}
        }

        case doctorScheduleConstants.CREATE_DOCTOR_AVAILABILITY_PENDING : {
            return {...state,createLoading:true,createFetched: false, user: action.payload}
        }  
        case  doctorScheduleConstants.CREATE_DOCTOR_AVAILABILITY_FULFILLED: {
            return {...state,createLoading: false,createFetched: true, doctorAvailability:action?.payload?.data?.data}
        }    
        case doctorScheduleConstants.CREATE_DOCTOR_AVAILABILITY_FAILURE :  {
            return {...state, createLoading: false,createFetched: false,error:action.error}
        }
       
        case doctorScheduleConstants.FETCH_DOCTOR_AVAILABLITY_PENDING : {
            return {...state,loading:true,fetched: false, user: action.payload}
        }  
        case  doctorScheduleConstants.FETCH_DOCTOR_AVAILABLITY_FULFILLED: {
            return {...state,loading: false,fetched: true, doctorAvailability:action?.payload?.data?.data}
        }    
        case doctorScheduleConstants.FETCH_DOCTOR_AVAILABLITY_FAILURE :  {
            return {...state, loading: false,fetched: false,error:action.error}
        }

        case doctorScheduleConstants.CHANGE_STATUS_OF_DOCTOR_AVAILABLITY_PENDING : {
            console.log("actionsssssssssssssssss",action.payload);
            return {...state,loading:true, fetched: false, user: action.payload}
        }  
        case  doctorScheduleConstants.CHANGE_STATUS_OF_DOCTOR_AVAILABLITY_FULFILLED: {
            console.log("actionsssssssssssssssss",action.payload);
            return {...state,loading: false,fetched: true, doctorAvailability:action?.payload?.data?.data}
        }    
        case doctorScheduleConstants.CHANGE_STATUS_OF_DOCTOR_AVAILABLITY_FAILURE :  {
            console.log("actionsssssssssssssssss",action.payload);
            return {...state, loading: false,fetched: false,error:action.payload?.error}
        }
        case doctorScheduleConstants.CHANGE_STATUS_OF_DOCTOR_AVAILABLITY_REJECTED :  {
            console.log("actionsssssssssssssssss",action.payload);
            return {...state, loading: false,fetched: false,error:action.payload?.error}
        }

        case doctorScheduleConstants.UPDATE_DOCTOR_AVAILABLITY_PENDING : {
            return {...state,updateLoading:true, updateFetched: false, user: action.payload}
        }  
        case  doctorScheduleConstants.UPDATE_DOCTOR_AVAILABLITY_FULFILLED: {
            return {...state,updateLoading: false,updateFetched: true, doctorAvailability:action?.payload?.data?.data}
        }    
        case doctorScheduleConstants.UPDATE_DOCTOR_AVAILABLITY_FAILURE :  {
            return {...state, updateLoading: false,updateFetched: false, error:action.error}
        }

        case doctorScheduleConstants.CLEAR_DOCTOR_AVAILABLITY : {
            return {...state,loading:true, fetched: false, user: action.payload}
        }  
    
        case doctorScheduleConstants.DOCTOR_CLEAR_SAVE : {
            return {...state,loading:true, fetched: false,createLoading:false,updateFetched:false, createFetched: false}
        }  
    
        default :
            return state;
    }

}
